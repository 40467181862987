//====================================================
//  Helpers
//====================================================
// Misc
@import 'helpers/misc/_variables';
@import 'helpers/misc/_mixins';
@import 'helpers/misc/_fonts';
@import 'helpers/misc/_debug';
@import 'helpers/misc/_grid';

// Base
@import 'helpers/_animate-base';
@import 'helpers/_typography';
// @import "helpers/_animate-advanced";
// @import "helpers/_print";

// Form
@import 'helpers/form/_form-base';
@import 'helpers/form/_form-placeholder';
@import 'helpers/form/_form-validation';
@import 'helpers/form/_checkbox';
// @import 'helpers/form/_quantity';

// Class
// @import "helpers/class/_border";
// @import "helpers/class/_float";
// @import "helpers/class/_row";
@import 'helpers/class/_button';
@import 'helpers/class/_color';
@import 'helpers/class/_gutter';
@import 'helpers/class/_hidden-content';
@import 'helpers/class/_hidden';
@import 'helpers/class/_hr';
@import 'helpers/class/_icon';
@import 'helpers/class/_img';
@import 'helpers/class/_aspect-ratio';
@import 'helpers/class/_margin';
@import 'helpers/class/_padding';
@import 'helpers/class/_position';
@import 'helpers/class/_size';
@import 'helpers/class/_txt';
@import 'helpers/class/_z-index';
@import 'helpers/class/_opacity';
@import 'helpers/class/_dots';
@import 'helpers/class/_ie';

//====================================================
//  Base
//====================================================
@import 'base/_common';
@import 'base/_headlines';
@import 'base/_link';
@import 'base/_paragraph';
@import 'base/_list';
//
//====================================================
//  Overrides
//====================================================
@import 'overrides/_swiper';
@import 'overrides/_select';
// @import 'overrides/_slick-default';
// @import 'overrides/_slick';
// @import 'overrides/_range-slider';
// @import 'overrides/_calendar';
// @import 'overrides/_tooltip';
//
//====================================================
//  Layout
//====================================================
@import 'layout/_content';
@import 'layout/_footer';
@import 'layout/_header';
//
//====================================================
//  Components
//====================================================
@import 'components/uni/_social';
@import 'components/uni/_language';
@import 'components/uni/_map';
@import 'components/uni/_modal';
@import 'components/uni/_file-upload';
@import 'components/uni/_article';
@import 'components/uni/_pagination';
// @import 'components/uni/_accordion';
// @import 'components/uni/_tabs';
// @import 'components/uni/_breadcrumbs';
// @import 'components/uni/_card';
// @import 'components/uni/_rate';
// @import 'components/uni/_tooltip';
// @import 'components/uni/_dropdown';
// @import 'components/uni/_table';
// @import 'components/uni/_load-more';
// @import 'components/uni/_cookies';
// @import 'components/uni/_share';
// @import 'components/uni/_box';

// @import "components/uni/_page-loader";
// @import "components/uni/_text-style";
// @import "components/block/_newsletter";
@import 'components/block/_hero';
@import 'components/block/_team';
@import 'components/block/_job';
@import 'components/block/_clip-text';
@import 'components/block/_offer';
@import 'components/block/_process';
@import 'components/block/_side-panel';
@import 'components/block/_category';

.simpleParallax {
  height: 100%;
}
