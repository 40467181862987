/* Form validation */

// .form__field-validation{
// }

.has-field-error {
  .form__field-message {
    display: block;
  }
  &.select2-container .select2-selection--single,
  &.select-classic .select2-container .select2-selection--multiple,
  &.form__textarea,
  &.form__input {
    border-color: $color-field-error !important;
  }
  & + .select2-container .select2-selection--single,
  & + .select-classic .select2-container .select2-selection--multiple {
    border-color: $color-field-error !important;
  }
}

.has-field-success {
  &.select2-container .select2-selection--single,
  &.select-classic .select2-container .select2-selection--multiple,
  &.form__textarea,
  &.form__input {
    border-color: $color-field-success !important;
  }
  & + .select2-container .select2-selection--single,
  & + .select-classic .select2-container .select2-selection--multiple {
    border-color: $color-field-success !important;
  }
}

.parsley-error,
.has-error {
  &.select2-container .select2-selection--single,
  &.select-classic .select2-container .select2-selection--multiple,
  &.form__textarea,
  &.form__input {
    border-color: $color-field-error !important;
  }
  & + .select2-container .select2-selection--single,
  & + .select-classic .select2-container .select2-selection--multiple {
    border-color: $color-field-error !important;
  }
}

.has-field-success,
.parsley-success {
  &.select2-container .select2-selection--single,
  &.select-classic .select2-container .select2-selection--multiple,
  &.form__textarea,
  &.form__input {
    border-color: $color-field-success !important;
  }
  & + .select2-container .select2-selection--single,
  & + .select-classic .select2-container .select2-selection--multiple {
    border-color: $color-field-success !important;
  }
}

.form__field-message {
  display: none;
  margin-top: 5px;
  color: $color-field-error;
  text-align: right;
  @include font-size(12px);
}

.parsley-errors-list {
  color: $color-field-error;
  text-align: right;
  @include font-size(12px);
  li {
    display: block;
    margin-top: 5px;
  }
}
