/* .checkbox.checkbox--radiobox
	input#checkbox_payment_1.checkbox__input-hidden(type='radio', name="payment" required='')
	label.checkbox__label(for='checkbox_payment_1')
		span.checkbox__box
		span.checkbox__text Platba kartou */

/* Checkbox helpers */

.checkbox {
  margin-bottom: 2rem;
}

.input-hidden,
.checkbox__input-hidden {
  position: absolute;
  opacity: 0;
  z-index: -1;
  // display: none;
  // appearance: none;
  // -webkit-appearance: none;
  // -moz-appearance: none;
}

.checkbox__label {
  margin: 0;
  padding-left: 0;
  min-height: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding-top: 0;
  cursor: pointer;
  text-transform: none;
  // justify-content: center;
}

.checkbox__box {
  position: relative;
  max-width: 18px;
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  border: 1px solid $color-black;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}

.checkbox__text {
  max-width: calc(100% - 18px);
  flex: 0 0 calc(100% - 18px);
  width: calc(100% - 18px);
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  cursor: pointer;
  text-align: left;
  color: $color-black;
  @include font-size(12px);
  padding-left: 0.6rem;
  line-height: 1.2;
}

.checkbox__slider {
  position: absolute;
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  border-radius: 50% !important;
  background-color: $color-black;
  transition: all 0.2s ease;
}

.checkbox__input-hidden:checked + .checkbox__label {
  .checkbox__box {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: 5px;
      width: 6px;
      height: 11px;
      border: solid $color-black;
      border-width: 0 2px 2px 0;
      transform: rotate(40deg);
    }
  }
}

// Checkbox SWITCHER
.checkbox--switcher {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 26px;
  .checkbox__label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid $color-black;
    border-radius: 35px;
    background-color: transparent;
  }
  .checkbox__input-hidden:checked + .checkbox__label {
    border-color: $color-red;
    &:after {
      display: none;
    }
    .checkbox__slider {
      background-color: $color-red;
      left: calc(100% - 20px);
    }
  }
}

// Checkbox RADIO
.checkbox--radiobox {
  margin-bottom: 2rem;
  .checkbox__box {
    top: 0;
    max-width: 20px;
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
    border: 2px solid $color-black;
    // background-color: rgba(0,0,0,0.03);
    @include border-radius(100%);
  }
  .checkbox__input-hidden:checked + .checkbox__label {
    .checkbox__box {
      // border: 2px solid $color-black;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        @include border-radius(100%);
        margin-top: -5px;
        margin-left: -5px;
        width: 10px;
        height: 10px;
        background-color: $color-black;
      }
    }
  }
}
