/* Buttons helper */

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  padding: 0.6em 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  outline: none !important;
  background: $color-black;
  text-align: center;
  font-family: $font-primary-medium;

  @include transition(background-color, 250ms, ease);
  @include border-radius(0);
  @include font-size(18px);
  -webkit-appearance: none;
  &,
  &:visited,
  &:active,
  &:focus {
    color: white;
  }

  &:hover {
    @include transition(background-color, 250ms, ease);
    @include bp(medium) {
      cursor: pointer;
      text-decoration: none;
      color: white;
    }
  }
}

.btn--responsive {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
}

// Buttons FONT SIZE
.btn--font-size-xsmall {
  @include font-size(12px);
}

.btn--font-size-small {
  @include font-size(14px);
}

.btn--font-size-medium {
  @include font-size(16px);
}

.btn--font-size-big {
  @include font-size(18px);
}

.btn--font-size-large {
  @include font-size(20px);
}

// Buttons SIZE
.btn--size-small {
  padding: 0.6em 1.2rem;
}

.btn--size-medium {
  padding: 1.2em 1.5rem;
}

.btn--size-big {
  padding: 2.2em 2rem;
}

// Buttons MIN WIDTH
// .btn--min-width-xsmall {
//   min-width: 120px;
// }

// .btn--min-width-small {
//   min-width: 160px;
// }

.btn--min-width-medium {
  min-width: 140px;
}

// .btn--min-width-big {
//   min-width: 300px;
// }

.btn-icon {
  margin-left: 1rem;
}

// Buttons TYPES
.btn-black-fill {
  color: $color-white;
  border: 2px solid transparent;
  background-color: $color-black;
  &,
  &:visited,
  &:active,
  &:focus {
    color: $color-white;
  }
  &:hover {
    color: $color-white;
    background-color: scale-color($color-red, $lightness: -20%);
  }
}

.btn-red-fill {
  color: $color-white;
  border: 2px solid transparent;
  background-color: $color-red;
  &,
  &:visited,
  &:active,
  &:focus {
    color: $color-white;
  }
  &:hover {
    color: $color-white;
    background-color: scale-color($color-red, $lightness: -20%);
    .btn__icon {
      color: $color-white;
    }
  }
  .btn__icon {
    color: $color-white;
  }
}

.btn-gray-outline {
  padding: 0;
  color: $color-black;
  background-color: transparent;
  margin-left: -0.5rem;
  font-family: $font-primary-regular;
  font-weight: 400;
  .btn-text {
    padding: 0.4em 0.6rem;
    position: relative;
    z-index: 1;
    &:after {
      content: '';
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100%;
      height: 7px;
      background-color: $color-gray-light;
      z-index: -1;
      transition: all 250ms ease-out;
    }
  }
  .btn-icon {
    display: inline-block;
    top: 3px;
    position: relative;
    transition: transform 250ms ease-out;
  }
  &,
  &:visited,
  &:active,
  &:focus {
    color: $color-black;
  }
  &:hover {
    .btn-text {
      &:after {
        height: 100%;
        transform: translate(-50%, 6px);
      }
    }
    color: $color-black;
    // background-color: $color-black;
    .btn-icon {
      transform: translateX(5px);
    }
  }
}

.btn-gray-outline--white {
  .btn-text {
    &:after {
      background-color: $color-white;
    }
  }
}
