/* Image helpers */

.img {
  display: block;
}

.img--full {
  width: 100%;
}

.img--responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img--cover {
  height: 100%;
  object-fit: cover;
}

.img--contain {
  height: 100%;
  object-fit: contain;
}

.img--shadow {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.bg-image-hover,
.img-hover {
  display: block;
  overflow: hidden;
  &:focus,
  &:hover {
    > img {
      transform: scale(1.05, 1.05);
    }
  }
  img {
    transition: transform 400ms ease-out;
    will-change: transform;
  }
}

.img-flex-contain,
.img-flex-cover {
  display: flex;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.img-flex-cover {
  img {
    object-fit: cover;
  }
}

.img-flex-contain {
  img {
    object-fit: contain;
  }
}

.img-flex-client {
  height: 100px;
}

.img-flex-article {
  height: 240px;
}

.bg-image,
.bg-rectangle {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-image-logo-our-team {
  left: auto;
  right: -40px;
  bottom: 180px;
  top: auto;
  width: auto;
  height: auto;
  @include bp(xsmall) {
    right: -80px;
  }
  svg {
    fill: $color-gray-light;
    @include font-size(200px);
    @include bp(xsmall) {
      @include font-size(310px);
    }
  }
}

.bg-image-logo-how-works {
  left: -30px;
  top: auto;
  bottom: -80px;
  width: auto;
  height: auto;
  @include bp(xsmall) {
    left: -60px;
    bottom: -40px;
  }
  svg {
    fill: $color-gray-light;
    @include font-size(160px);
    @include bp(xsmall) {
      @include font-size(310px);
    }
  }
}

.bg-image-our-team {
  width: 100vw;
  right: auto;
  left: calc(-50vw + 50%);
  background-color: $color-gray-light;
  object-fit: cover;
  z-index: -2;
  position: relative;
  @include bp(small) {
    width: calc(100vw - 44vw);
    left: auto;
    right: calc(-50vw + 100%);
    position: absolute;
  }
}

.bg-image-logo-process {
  left: auto;
  top: auto;
  bottom: -80px;
  width: auto;
  height: auto;
  @include bp(xsmall) {
    right: -40px;
    bottom: -80px;
  }
  svg {
    fill: $color-gray-light;
    @include font-size(160px);
    @include bp(xsmall) {
      @include font-size(310px);
    }
  }
}

.bg-image-logo-position {
  left: auto;
  right: -40px;
  top: 200px;
  width: auto;
  height: auto;
  @include bp(xsmall) {
    top: 100px;
  }
  @include bp(small) {
    right: auto;
    left: -120px;
    top: 500px;
  }
  svg {
    fill: $color-gray-light;
    @include font-size(200px);
    @include bp(xsmall) {
      @include font-size(310px);
    }
  }
}

.bg-rectangle-our-team {
  width: 100vw;
  left: calc(-50vw + 50%);
  background-color: $color-gray-light;
  z-index: -1;
  @include bp(small) {
    width: calc(100vw - 50vw);
    left: calc(-50vw + 100%);
  }
}

.bg-rectangle-work {
  width: 100vw;
  left: calc(-50vw + 50%);
  background-color: $color-gray-light;
  z-index: -1;
  display: none;
  @include bp(small) {
    display: block;
    left: auto;
    width: calc(100vw - 55vw);
    right: calc(-50vw + 50%);
    height: 590px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.bg-rectangle-homepage-blog {
  top: 0;
  left: auto;
  right: 0;
  height: 425px;
  background-color: $color-gray-light;
  display: none;
  @include bp(small) {
    display: block;
    top: 70px;
    height: 435px;
    width: calc(((100% - 730px) / 2) + (730px / 2) - 30px);
  }
  @include bp(medium) {
    width: calc(((100% - 950px) / 2) + ((950px * 2) / 3) - 25px);
    height: 441px;
  }
  @include bp(large) {
    width: calc(((100% - 1130px) / 2) + ((1130px * 2) / 3) - 25px);
  }
}

.img-group-negative-sm {
  @include bp(rev-small) {
    margin: 0 -23px;
  }
}

.negative-offset-sm {
  @include bp(rev-small) {
    margin: 0 -23px;
  }
}

.negative-offset-ext-sm {
  @include bp(rev-small) {
    margin: 0 -15px;
  }
}

.img-offset-left {
  @include bp(small) {
    margin-left: -70px;
    width: calc(100% + 70px);
    max-width: calc(100% + 70px);
  }
}

.youtube-block {
  position: relative;
  padding-bottom: 56.25%;
  margin: 0 -23px;
  @include bp(small) {
    margin: 0;
    height: 219px;
    width: calc(100% + 40px);
    margin-left: -40px;
  }
  @include bp(medium) {
    height: 304px;
    width: calc(100% + 80px);
    margin-left: -80px;
  }
  @include bp(large) {
    height: 354px;
    width: calc(100% + 80px);
    margin-left: -80px;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
