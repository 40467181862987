/* Modal block */

.modal__overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  @include transition(opacity, 400ms, ease);
  &.is-active {
    width: 100%;
    height: 100%;
    opacity: 1;
    overflow: visible;
    visibility: visible;
  }
}

.modal {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  width: auto;
  height: 0;
  padding: 3rem 0 2rem;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: center;
  @include transition(opacity, 400ms, ease);
  @include bp(small) {
    padding: 4rem 0 4rem;
  }
  &.is-active {
    visibility: visible;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    opacity: 1;

    @include transition(opacity, 400ms, ease);
    .modal__box {
      position: relative;
      top: auto;
      left: auto;

      @include transform(translate(0, 0));
    }
  }
  &.modal__hidden {
    .modal__close,
    .modal__scroll {
      display: none;
    }
  }
}

.modal__scroll {
  width: 100%;
  margin: auto 1rem;
}

.modal__box {
  margin: 0 auto;
  padding: 0;
  width: 100%;

  @include bp(small) {
    width: 600px;
  }
}

.modal__close {
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  display: flex;
  width: 50px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  text-align: center;
  // color: $color-black;
  // background: white;
  align-items: center;
  justify-content: center;
  svg {
    position: relative;
    transform: rotateZ(-45deg);
    fill: $color-red;
    @include font-size(22px);
  }
  &:focus,
  &:hover {
    background-color: $color-red;
    svg {
      fill: $color-white;
    }
  }
}

.modal__body {
  box-shadow: 0 10px 30px rgba(175, 175, 175, 0.5);
  background-color: $color-white;
  padding: calculate-rem(60px) calculate-rem(20px) calculate-rem(35px);
  @include bp(small) {
    padding: calculate-rem(60px) calculate-rem(40px) calculate-rem(35px);
  }
}
