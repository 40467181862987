.pagination-list__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  line-height: 50px;
  color: $color-red;
  @include font-size(24px);
  cursor: pointer;
  &:focus,
  &:hover {
    svg {
      fill: darken($color-red, 10%);
    }
  }
  svg {
    fill: $color-red;
  }
  &.is-disabled {
    opacity: 0.2;
  }
}

.pagination-list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0;
  text-align: center;
}

.pagination-list__item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:focus,
  &:hover,
  &.is-active {
    .pagination-list__link {
      font-family: $font-primary-bold;
      font-weight: 700;
    }
  }
}

.pagination-list__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: $color-black;
  border-radius: 100%;
  font-family: $font-primary-light;
  font-weight: 300;
  text-transform: uppercase;
  @include font-size(24px);
}
