/* Header */
.header__navigation {
  .main-nav {
    position: fixed;
    z-index: 98;
    top: 0;
    left: 0;
    // transform: translateX(-100%);
    background-color: $nav-bg-color-active;

    // transition: none;
    @include bp(rev-small) {
      display: flex;
      align-items: center;
    }
  }
  .container-nav {
    transform: scale(0.8);
    transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    will-change: transform;
  }

  // &:not(.is-collapse) .header__hamburger-icon-bg {
  //   display: block;
  // }

  &:not(.is-collapse) .header__hamburger:hover .header__hamburger-icon {
    &::after {
      margin-top: 13px;
    }
    &::before {
      margin-top: -13px;
    }
  }

  .header__hamburger {
    position: absolute;
    z-index: 200;
    right: 0;
    display: block;
    width: 60px;
    height: 60px;
    cursor: pointer;
    top: 30px;
    @include bp(small) {
      top: 45px;
    }
  }

  .header__hamburger-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 40px;
    height: 3px;
    transition-duration: 250ms;
    transition-property: background-color, transform;
    transform: translate(-50%, -50%);
    background-color: $nav-bg-color;
    font-weight: 300;
    border-radius: 5px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 20px;
      height: 3px;
      transition-duration: 250ms;
      border-radius: 5px;
      transition-property: margin, transform;
      background: $nav-bg-color;
    }

    &:before {
      margin-top: -10px;
      right: 0;
    }

    &:after {
      margin-top: 10px;
    }
  }

  // .header__hamburger-icon-bg {
  //   display: none;
  //   &:before,
  //   &:after {
  //     content: '';
  //     position: absolute;
  //     display: block;
  //     width: 20px;
  //     height: 8px;
  //     transition-duration: 250ms;
  //     border-radius: 5px;
  //     transition-property: margin, transform;
  //     background: $nav-bg-color;
  //   }
  //   &:after {
  //     bottom: 15px;
  //     right: 9px;
  //   }
  //   &:before {
  //     top: 15px;
  //     left: 9px;
  //   }
  // }

  &.is-collapse.header__navigation {
    position: fixed;
  }

  &.is-collapse .main-nav {
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: visible;
    overflow: auto;
    padding-top: 120px;
    padding-bottom: 50px;
    @include bp(small) {
      padding-top: 150px;
      padding-bottom: 100px;
    }
    .container-nav {
      transform: scale(1);
    }
    // transform: translateX(0);

    // @include transition(transform, 250ms, ease);
  }

  &.is-collapse .header__hamburger-icon {
    transition: none;
    background: rgba(0, 0, 0, 0);

    &:before {
      width: 40px;
      background-color: $color-red;
      margin-top: 0;
      transform: rotate(45deg);
    }

    &:after {
      width: 40px;
      background-color: $color-red;
      margin-top: 0;
      transform: rotate(-45deg);
    }
  }
}

.page-index {
  .header__navigation:not(.is-collapse) .header__logo-link {
    svg {
      fill: $color-white;
    }
  }
  .header__navigation:not(.is-collapse) {
    .header__hamburger-icon {
      background-color: $color-white;
      &:before,
      &:after {
        background: $color-white;
      }
    }
  }
}

.header__navigation {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 15px;
  @include bp(medium) {
    padding: 0 30px;
    box-shadow: none;
  }
  &.is-scrolling {
    @include bp(medium) {
      .header__logo-link {
        svg {
          fill: $color-black;
        }
      }
    }
  }
}

.header__flex {
  @include bp(medium) {
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }
}

.header__logo {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 99;
  text-align: center;
  top: 30px;
  @include bp(small) {
    top: 50px;
  }
}

.header__logo-link {
  display: inline-block;
  vertical-align: middle;
  svg {
    fill: $color-black;
    @include font-size(50px);
  }
}

.main-nav {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: opacity 250ms ease-out;
  will-change: opacity;
}

.main-nav__item {
  text-align: center;
  @include bp(small) {
    text-align: left;
    margin-bottom: calculate-rem(35px);
    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }
  @include bp(rev-small) {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.main-nav__link {
  padding: 0.5rem 0;
  &:hover {
    color: $color-red;
  }
}
