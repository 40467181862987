p {
  display: block;
  margin-top: 0;
  margin-bottom: 2rem;
  color: $color-black-light;
  font-family: $font-primary-light;
  font-weight: 300;
  line-height: 1.4;
  transition: font-size 0.3s cubic-bezier(0.16, 0.08, 0.355, 1);
  @include font-size(14px);

  a {
    color: currentColor;
  }
}

.p-headline {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  display: inline-block;
  background-color: $color-white;
  padding: 0 1.5rem;
  min-width: 220px;
}

// Paragraph FONT SIZE
.p--size-12 {
  p {
    @include font-size(12px);
  }
}

.p--size-14 {
  p {
    @include font-size(14px);
  }
}

.p--size-18 {
  p {
    @include font-size(18px);
  }
}

.p--size-20 {
  p {
    @include font-size(20px);
    @include bp(xsmall) {
      @include font-size(20px);
    }
  }
}

.p--size-24 {
  p {
    @include font-size(20px);
    @include bp(xsmall) {
      @include font-size(24px);
    }
  }
}

// Paragraph Font weight
.p--weight-400 {
  p {
    font-family: $font-primary-regular;
    font-weight: 400;
  }
}

// Paragraph LINE HEIGHT
.p--line-1 {
  p {
    line-height: 1;
  }
}

.p--line-1-2 {
  p {
    line-height: 1.2;
  }
}

.p--line-1-4 {
  p {
    line-height: 1.4;
  }
}

.p--line-1-5 {
  p {
    line-height: 1.5;
  }
}

.p--line-1-6 {
  p {
    line-height: 1.6;
  }
}

.p--line-1-8 {
  p {
    line-height: 1.8;
  }
}

.p--line-2 {
  p {
    line-height: 2;
  }
}

// Paragraphs MARGIN
.p--margin-0 {
  p {
    margin-bottom: 0;
  }
}

.p--margin-01 {
  p {
    margin-bottom: 0.1rem;
  }
}

.p--margin-02 {
  p {
    margin-bottom: 0.2rem;
  }
}

.p--margin-03 {
  p {
    margin-bottom: 0.3rem;
  }
}

.p--margin-04 {
  p {
    margin-bottom: 0.4rem;
  }
}

.p--margin-05 {
  p {
    margin-bottom: 0.5rem;
  }
}

.p--margin-06 {
  p {
    margin-bottom: 0.6rem;
  }
}

.p--margin-07 {
  p {
    margin-bottom: 0.7rem;
  }
}

.p--margin-08 {
  p {
    margin-bottom: 0.8rem;
  }
}

.p--margin-09 {
  p {
    margin-bottom: 0.9rem;
  }
}

.p--margin-1 {
  p {
    margin-bottom: 1rem;
  }
}

.p--margin-1-2 {
  p {
    margin-bottom: 1.2rem;
  }
}

.p--margin-1-5 {
  p {
    margin-bottom: 1.5rem;
  }
}

.p--margin-2 {
  p {
    margin-bottom: 2rem;
  }
}

.p--margin-2-5 {
  p {
    margin-bottom: 2.5rem;
  }
}
