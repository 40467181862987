@if ($grid) {
  /* GRID switcher */

  .grid-switcher {
    align-items: center;
    background-color: #363636;
    border-radius: 50%;
    bottom: 10px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 10px;
    justify-content: center;
    left: 10px;
    position: fixed;
    width: 40px;
    height: 40px;
    text-transform: uppercase;
    transition: opacity 0.25s ease-in-out;
    z-index: 99999999;
    font-family: $font-primary-regular;
    &.is-active,
    &:focus,
    &:hover {
      color: $color-black;
      background-color: #ffe5e5;
    }
  }

  .grid-container {
    font-size: 0;
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 9999999;
    opacity: 0;
    overflow: hidden;
    transition: opacity 600ms ease;
    &.is-active {
      // width: auto;
      opacity: 1;
      overflow: visible;
      transition: opacity 600ms ease;
    }
  }

  .grid-column-item {
    width: 100%;
    min-width: 1px;
    min-height: 0;
    width: calc(100% / 6);
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
    @include bp(small) {
      width: calc(100% / 12);
      flex: 0 0 calc(100% / 12);
      max-width: calc(100% / 12);
    }
  }

  .grid-column {
    background-color: rgba(255, 0, 0, 0.1);
    height: 100vh;
  }
}
