/* Animate helpers */
@-webkit-keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
  animation-name: spinner-rotate;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border: 10px solid $color-red;
  border-right-color: transparent;
  border-radius: 50%;
}
