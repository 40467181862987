/* Content */

section {
  position: relative;
  padding: 3rem 0 3rem;

  @include bp(small) {
    padding: 3rem 0 3rem;
  }
}

.section-divider-bottom {
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: 150px;
    height: 2px;
    background-color: $color-red;
    transform: translateX(-50%);
  }
}

.padding-style-1 {
  padding: 1rem 0 1rem;

  @include bp(small) {
    padding: 1rem 0 1rem;
  }
}

.padding-style-2 {
  padding: 2rem 0 2rem;

  @include bp(small) {
    padding: 2rem 0 2rem;
  }
}

.padding-style-3 {
  padding: 2rem 0 2rem;

  @include bp(small) {
    padding: 3rem 0 3rem;
  }
}

.padding-style-3-8 {
  padding: 0 0 4rem;

  @include bp(small) {
    padding: 3rem 0 8rem;
  }
}

.padding-style-4 {
  padding: 3rem 0 3rem;

  @include bp(small) {
    padding: 4rem 0 4rem;
  }
}

.padding-style-5 {
  padding: 3rem 0 3rem;

  @include bp(small) {
    padding: 5rem 0 5rem;
  }
}

.padding-style-6-22 {
  padding: 4rem 0.5rem 3rem;

  @include bp(small) {
    padding: 6rem 0 22rem;
  }
}

.padding-style-6-3 {
  padding: 4rem 0.5rem 3rem;

  @include bp(small) {
    padding: 6rem 0 3rem;
  }
}

.padding-style-6-6 {
  padding: 4rem 0.5rem 3rem;

  @include bp(small) {
    padding: 6rem 0 2rem;
  }
  @include bp(medium) {
    padding: 6rem 0 6rem;
  }
}

.padding-style-6-6-ext {
  padding: 4rem 0.5rem 3rem;

  @include bp(medium) {
    padding: 6rem 0 6rem;
  }
}

.padding-style-6-4 {
  padding: 4rem 1.5rem 4rem;

  @include bp(small) {
    padding: 6rem 1rem 4rem;
  }
  @include bp(medium) {
    padding: 6rem 1rem 4rem;
  }
}

.padding-style-6-5 {
  padding: 5rem 1.5rem 4rem;

  @include bp(small) {
    padding: 6rem 1rem 5rem;
  }
}

.padding-style-6-5-ext {
  padding: 5rem 1rem 2rem;

  @include bp(medium) {
    padding: 6rem 1rem 5rem;
  }
}

.padding-style-6-8 {
  padding: 2rem 0.5rem 2rem;

  @include bp(small) {
    padding: 6rem 0 8rem;
  }
}

.padding-style-6-9 {
  padding: 5rem 1.5rem 4rem;

  @include bp(small) {
    padding: 6rem 0 9rem;
  }
}

.padding-style-6-10 {
  padding: 4rem 0.5rem 6rem;

  @include bp(small) {
    padding: 6rem 0 10rem;
  }
}

.padding-style-6 {
  padding: 3rem 0 3rem;

  @include bp(small) {
    padding: 6rem 0 6rem;
  }
}

.padding-style-7 {
  padding: 5rem 1rem 4rem;

  @include bp(medium) {
    padding: 7rem 0 7rem;
  }
}

.padding-style-8 {
  padding: 3rem 0 3rem;

  @include bp(small) {
    padding: 8rem 0 8rem;
  }
}

.padding-style-8-10 {
  padding: 3rem 0 3rem;

  @include bp(medium) {
    padding: 8rem 0 10rem;
  }
}

.padding-style-10 {
  padding: 4rem 0.5rem 4rem;

  @include bp(small) {
    padding: 6rem 0 6rem;
  }
  @include bp(medium) {
    padding: 10rem 0 10rem;
  }
}

.padding-style-10-9 {
  padding: 6rem 0.5rem 3rem;

  @include bp(small) {
    padding: 10rem 0 4rem;
  }
  @include bp(medium) {
    padding: 10rem 0 9rem;
  }
}

.padding-style-10-10 {
  padding: 6rem 0.5rem 6rem;

  @include bp(small) {
    padding: 10rem 0 10rem;
  }
}

.padding-style-10-10-ext {
  padding: 2rem 0.5rem 6rem;

  @include bp(small) {
    padding: 4rem 0 8rem;
  }
  @include bp(medium) {
    padding: 10rem 0 10rem;
  }
}

.padding-style-10-13 {
  padding: 6rem 0.5rem 4rem;

  @include bp(small) {
    padding: 10rem 0.5rem 6rem;
  }
  @include bp(medium) {
    padding: 10rem 0 13rem;
  }
}

.padding-content-1 {
  padding: 1rem 1rem;
}

.padding-content-2 {
  padding: 2rem 2rem;
}

.padding-content-3-0 {
  padding: 3rem 0;
}

.padding-content-4 {
  padding: 4rem 4rem;
}

.padding-content-5 {
  padding: 5rem 5rem;
}

.padding-content-6-0 {
  padding: 4rem 0;
  @include bp(small) {
    padding: 6rem 0;
  }
}

.padding-content-9-0 {
  padding: 4rem 0;
  @include bp(small) {
    padding: 9rem 2rem 9rem 0;
  }
  @include bp(medium) {
    padding: 9rem 5rem 9rem 0;
  }
}

@include bp(small) {
  .padding-content-2 {
    padding: 2rem 2rem;
  }
}

.content-width-200 {
  max-width: 200px;
}

.content-width-300 {
  max-width: 300px;
}

.content-width-400 {
  max-width: 400px;
}

.content-width-450 {
  max-width: 450px;
}

.content-width-500 {
  max-width: 500px;
}

.content-width-600 {
  max-width: 600px;
}

.content-width-700 {
  max-width: 700px;
}

.content-width-800 {
  max-width: 800px;
}

@include bp(small) {
  .content-width-sm-450 {
    max-width: 450px;
  }
}

.page-index {
  main {
    padding-top: 0;
  }
}

main {
  position: relative;
  display: block;
  padding-top: 140px;
  flex: 1 0 auto;
  @include bp(small) {
    padding-top: 120px;
  }
}

.container-full {
  margin: 0 auto;
}

.container-small,
.container-normal,
.container-nav,
.container-large {
  margin: 0 auto;
  padding: 0 15px;
}

.container-small {
  width: 100%;
  @include bp(small) {
    width: 720px;
  }
  @include bp(medium) {
    width: 900px;
  }
  @include bp(large) {
    width: 900px;
  }
  @include bp(xlarge) {
    width: 900px;
	}
	@include bp(xxxxlarge) {
		width: 1400px;
	}
}

.container-normal {
  width: 100%;
  @include bp(small) {
    width: 730px;
  }
  @include bp(medium) {
    width: 950px;
  }
  @include bp(large) {
    width: 1130px;
  }
  @include bp(xlarge) {
    width: 1130px;
	}
	@include bp(xxxxlarge) {
		width: 1800px;
	}
}

.container-nav {
  width: 100%;
  @include bp(small) {
    width: 730px;
  }
  @include bp(medium) {
    width: 950px;
  }
  @include bp(large) {
    width: 1000px;
  }
  @include bp(xlarge) {
    width: 1000px;
	}
	@include bp(xxxxlarge) {
		width: 1500px;
	}
}

.container-large {
  width: 100%;
  @include bp(small) {
    width: 730px;
  }
  @include bp(medium) {
    width: 950px;
  }
  @include bp(large) {
    width: 1140px;
  }
  @include bp(xlarge) {
    width: 1360px;
  }
}
