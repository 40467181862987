/* Margin helpers */

.margin-0 {
  margin: 0 !important;
}

@include bp(xsmall) {
  .margin-xs-0 {
    margin: 0 !important;
  }
}

@include bp(small) {
  .margin-sm-0 {
    margin: 0 !important;
  }
}

@include bp(medium) {
  .margin-md-0 {
    margin: 0 !important;
  }
}

@include bp(large) {
  .margin-lg-0 {
    margin: 0 !important;
  }
}

@include bp(xlarge) {
  .margin-xl-0 {
    margin: 0 !important;
  }
}

// Margin bottom
.margin-bottom-01 {
  margin-bottom: 0.1rem;
}

.margin-bottom-02 {
  margin-bottom: 0.2rem;
}

.margin-bottom-03 {
  margin-bottom: 0.3rem;
}

.margin-bottom-04 {
  margin-bottom: 0.4rem;
}

.margin-bottom-05 {
  margin-bottom: 0.5rem;
}

.margin-bottom-06 {
  margin-bottom: 0.6rem;
}

.margin-bottom-07 {
  margin-bottom: 0.7rem;
}

.margin-bottom-08 {
  margin-bottom: 0.8rem;
}

.margin-bottom-09 {
  margin-bottom: 0.9rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-bottom-1-5 {
  margin-bottom: 1.5rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-bottom-2-5 {
  margin-bottom: 2.5rem;
}

.margin-bottom-3 {
  margin-bottom: 3rem;
}

.margin-bottom-3-5 {
  margin-bottom: 3.5rem;
}

.margin-bottom-4 {
  margin-bottom: 4rem;
}

.margin-bottom-5 {
  margin-bottom: 5rem;
}

.margin-bottom-6 {
  margin-bottom: 6rem;
}

.margin-bottom-7 {
  margin-bottom: 7rem;
}

.margin-bottom-8 {
  margin-bottom: 8rem;
}

.margin-bottom-9 {
  margin-bottom: 9rem;
}

.margin-bottom-10 {
  margin-bottom: 10rem;
}

@include bp(xsmall) {
  .margin-bottom-xs-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-xs-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-xs-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-xs-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-xs-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-xs-6 {
    margin-bottom: 6rem;
  }
}

@include bp(small) {
  .margin-bottom-sm-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-sm-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-sm-2-5 {
    margin-bottom: 2.5rem;
  }
  .margin-bottom-sm-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-sm-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-sm-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-sm-6 {
    margin-bottom: 6rem;
  }
  .margin-bottom-sm-7 {
    margin-bottom: 7rem;
  }
  .margin-bottom-sm-8 {
    margin-bottom: 8rem;
  }
}

@include bp(medium) {
  .margin-bottom-md-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-md-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-md-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-md-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-md-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-md-6 {
    margin-bottom: 6rem;
  }
  .margin-bottom-md-7 {
    margin-bottom: 7rem;
  }
  .margin-bottom-md-8 {
    margin-bottom: 8rem;
  }
  .margin-bottom-md-10 {
    margin-bottom: 10rem;
  }
  .margin-bottom-md-12 {
    margin-bottom: 12rem;
  }
}

@include bp(large) {
  .margin-bottom-lg-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-lg-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-lg-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-lg-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-lg-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-lg-6 {
    margin-bottom: 6rem;
  }
  .margin-bottom-lg-8 {
    margin-bottom: 8rem;
  }
}

@include bp(xlarge) {
  .margin-bottom-xl-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-xl-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-xl-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-xl-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-xl-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-xl-6 {
    margin-bottom: 6rem;
  }
}

// Margin top
.margin-top-01 {
  margin-top: 0.1rem;
}

.margin-top-02 {
  margin-top: 0.2rem;
}

.margin-top-03 {
  margin-top: 0.3rem;
}

.margin-top-04 {
  margin-top: 0.4rem;
}

.margin-top-05 {
  margin-top: 0.5rem;
}

.margin-top-06 {
  margin-top: 0.6rem;
}

.margin-top-07 {
  margin-top: 0.7rem;
}

.margin-top-08 {
  margin-top: 0.8rem;
}

.margin-top-09 {
  margin-top: 0.9rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-1-5 {
  margin-top: 1.5rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-2-5 {
  margin-top: 2.5rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-top-3-5 {
  margin-top: 3.5rem;
}

.margin-top-4 {
  margin-top: 4rem;
}

.margin-top-5 {
  margin-top: 5rem;
}

.margin-top-6 {
  margin-top: 6rem;
}

.margin-top-7 {
  margin-top: 7rem;
}

.margin-top-8 {
  margin-top: 8rem;
}

.margin-top-9 {
  margin-top: 9rem;
}

.margin-top-10 {
  margin-top: 10rem;
}

@include bp(xsmall) {
  .margin-top-xs-1 {
    margin-top: 1rem;
  }
  .margin-top-xs-2 {
    margin-top: 2rem;
  }
  .margin-top-xs-3 {
    margin-top: 3rem;
  }
  .margin-top-xs-4 {
    margin-top: 4rem;
  }
  .margin-top-xs-5 {
    margin-top: 5rem;
  }
  .margin-top-xs-6 {
    margin-top: 6rem;
  }
}

@include bp(small) {
  .margin-top-sm-1 {
    margin-top: 1rem;
  }
  .margin-top-sm-2 {
    margin-top: 2rem;
  }
  .margin-top-sm-2-5 {
    margin-top: 2.5rem;
  }
  .margin-top-sm-3 {
    margin-top: 3rem;
  }
  .margin-top-sm-4 {
    margin-top: 4rem;
  }
  .margin-top-sm-5 {
    margin-top: 5rem;
  }
  .margin-top-sm-6 {
    margin-top: 6rem;
  }
}

@include bp(medium) {
  .margin-top-md-1 {
    margin-top: 1rem;
  }
  .margin-top-md-2 {
    margin-top: 2rem;
  }
  .margin-top-md-2-5 {
    margin-top: 2.5rem;
  }
  .margin-top-md-3 {
    margin-top: 3rem;
  }
  .margin-top-md-4 {
    margin-top: 4rem;
  }
  .margin-top-md-5 {
    margin-top: 5rem;
  }
  .margin-top-md-6 {
    margin-top: 6rem;
  }
  .margin-top-md-8 {
    margin-top: 8rem;
  }
}

@include bp(large) {
  .margin-top-lg-1 {
    margin-top: 1rem;
  }
  .margin-top-lg-2 {
    margin-top: 2rem;
  }
  .margin-top-lg-3 {
    margin-top: 3rem;
  }
  .margin-top-lg-4 {
    margin-top: 4rem;
  }
  .margin-top-lg-5 {
    margin-top: 5rem;
  }
  .margin-top-lg-6 {
    margin-top: 6rem;
  }
  .margin-top-lg-8 {
    margin-top: 8rem;
  }
}

@include bp(xlarge) {
  .margin-top-xl-1 {
    margin-top: 1rem;
  }
  .margin-top-xl-2 {
    margin-top: 2rem;
  }
  .margin-top-xl-3 {
    margin-top: 3rem;
  }
  .margin-top-xl-4 {
    margin-top: 4rem;
  }
  .margin-top-xl-5 {
    margin-top: 5rem;
  }
  .margin-top-xl-6 {
    margin-top: 6rem;
  }
}

// Margin left
.margin-left-01 {
  margin-left: 0.1rem;
}

.margin-left-02 {
  margin-left: 0.2rem;
}

.margin-left-03 {
  margin-left: 0.3rem;
}

.margin-left-04 {
  margin-left: 0.4rem;
}

.margin-left-05 {
  margin-left: 0.5rem;
}

.margin-left-08 {
  margin-left: 0.8rem;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-left-1-5 {
  margin-left: 1.5rem;
}

.margin-left-2 {
  margin-left: 2rem;
}

.margin-left-3 {
  margin-left: 3rem;
}

.margin-left-4 {
  margin-left: 4rem;
}

.margin-left-5 {
  margin-left: 5rem;
}

.margin-left-6 {
  margin-left: 6rem;
}

.margin-left-7 {
  margin-left: 7rem;
}

.margin-left-8 {
  margin-left: 8rem;
}

.margin-left-9 {
  margin-left: 9rem;
}

.margin-left-10 {
  margin-left: 10rem;
}

@include bp(xsmall) {
  .margin-left-xs-1 {
    margin-left: 1rem;
  }
  .margin-left-xs-2 {
    margin-left: 2rem;
  }
  .margin-left-xs-3 {
    margin-left: 3rem;
  }
  .margin-left-xs-4 {
    margin-left: 4rem;
  }
  .margin-left-xs-5 {
    margin-left: 5rem;
  }
  .margin-left-xs-6 {
    margin-left: 6rem;
  }
}

@include bp(small) {
  .margin-left-sm-1 {
    margin-left: 1rem;
  }
  .margin-left-sm-2 {
    margin-left: 2rem;
  }
  .margin-left-sm-3 {
    margin-left: 3rem;
  }
  .margin-left-sm-4 {
    margin-left: 4rem;
  }
  .margin-left-sm-5 {
    margin-left: 5rem;
  }
  .margin-left-sm-6 {
    margin-left: 6rem;
  }
}

@include bp(medium) {
  .margin-left-md-1 {
    margin-left: 1rem;
  }
  .margin-left-md-2 {
    margin-left: 2rem;
  }
  .margin-left-md-3 {
    margin-left: 3rem;
  }
  .margin-left-md-4 {
    margin-left: 4rem;
  }
  .margin-left-md-5 {
    margin-left: 5rem;
  }
  .margin-left-md-6 {
    margin-left: 6rem;
  }
}

@include bp(large) {
  .margin-left-lg-1 {
    margin-left: 1rem;
  }
  .margin-left-lg-2 {
    margin-left: 2rem;
  }
  .margin-left-lg-3 {
    margin-left: 3rem;
  }
  .margin-left-lg-4 {
    margin-left: 4rem;
  }
  .margin-left-lg-5 {
    margin-left: 5rem;
  }
  .margin-left-lg-6 {
    margin-left: 6rem;
  }
}

@include bp(xlarge) {
  .margin-left-xl-1 {
    margin-left: 1rem;
  }
  .margin-left-xl-2 {
    margin-left: 2rem;
  }
  .margin-left-xl-3 {
    margin-left: 3rem;
  }
  .margin-left-xl-4 {
    margin-left: 4rem;
  }
  .margin-left-xl-5 {
    margin-left: 5rem;
  }
  .margin-left-xl-6 {
    margin-left: 6rem;
  }
}

// Margin right
.margin-right-01 {
  margin-right: 0.1rem;
}

.margin-right-02 {
  margin-right: 0.2rem;
}

.margin-right-03 {
  margin-right: 0.3rem;
}

.margin-right-04 {
  margin-right: 0.4rem;
}

.margin-right-05 {
  margin-right: 0.5rem;
}

.margin-right-08 {
  margin-right: 0.8rem;
}

.margin-right-1 {
  margin-right: 1rem;
}

.margin-right-1-5 {
  margin-right: 1.5rem;
}

.margin-right-2 {
  margin-right: 2rem;
}

.margin-right-3 {
  margin-right: 3rem;
}

.margin-right-4 {
  margin-right: 4rem;
}

.margin-right-5 {
  margin-right: 5rem;
}

.margin-right-6 {
  margin-right: 6rem;
}

.margin-right-7 {
  margin-right: 7rem;
}

.margin-right-8 {
  margin-right: 8rem;
}

.margin-right-9 {
  margin-right: 9rem;
}

.margin-right-10 {
  margin-right: 10rem;
}

@include bp(xsmall) {
  .margin-right-xs-1 {
    margin-right: 1rem;
  }
  .margin-right-xs-2 {
    margin-right: 2rem;
  }
  .margin-right-xs-3 {
    margin-right: 3rem;
  }
  .margin-right-xs-4 {
    margin-right: 4rem;
  }
  .margin-right-xs-5 {
    margin-right: 5rem;
  }
  .margin-right-xs-6 {
    margin-right: 6rem;
  }
}

@include bp(small) {
  .margin-right-sm-1 {
    margin-right: 1rem;
  }
  .margin-right-sm-2 {
    margin-right: 2rem;
  }
  .margin-right-sm-3 {
    margin-right: 3rem;
  }
  .margin-right-sm-4 {
    margin-right: 4rem;
  }
  .margin-right-sm-5 {
    margin-right: 5rem;
  }
  .margin-right-sm-6 {
    margin-right: 6rem;
  }
}

@include bp(medium) {
  .margin-right-md-1 {
    margin-right: 1rem;
  }
  .margin-right-md-2 {
    margin-right: 2rem;
  }
  .margin-right-md-3 {
    margin-right: 3rem;
  }
  .margin-right-md-4 {
    margin-right: 4rem;
  }
  .margin-right-md-5 {
    margin-right: 5rem;
  }
  .margin-right-md-6 {
    margin-right: 6rem;
  }
}

@include bp(large) {
  .margin-right-lg-1 {
    margin-right: 1rem;
  }
  .margin-right-lg-2 {
    margin-right: 2rem;
  }
  .margin-right-lg-3 {
    margin-right: 3rem;
  }
  .margin-right-lg-4 {
    margin-right: 4rem;
  }
  .margin-right-lg-5 {
    margin-right: 5rem;
  }
  .margin-right-lg-6 {
    margin-right: 6rem;
  }
}

@include bp(xlarge) {
  .margin-right-xl-1 {
    margin-right: 1rem;
  }
  .margin-right-xl-2 {
    margin-right: 2rem;
  }
  .margin-right-xl-3 {
    margin-right: 3rem;
  }
  .margin-right-xl-4 {
    margin-right: 4rem;
  }
  .margin-right-xl-5 {
    margin-right: 5rem;
  }
  .margin-right-xl-6 {
    margin-right: 6rem;
  }
}

.margin-center {
  margin: 0 auto;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-right-auto {
  margin-right: auto;
}

@include bp(xsmall) {
  .margin-xs-center {
    margin: 0 auto;
  }
  .margin-xs-left-auto {
    margin-left: auto;
  }
  .margin-xs-right-auto {
    margin-right: auto;
  }
}

@include bp(small) {
  .margin-sm-center {
    margin: 0 auto;
  }
  .margin-sm-left-auto {
    margin-left: auto;
  }
  .margin-sm-right-auto {
    margin-right: auto;
  }
}

@include bp(medium) {
  .margin-md-center {
    margin: 0 auto;
  }
  .margin-md-left-auto {
    margin-left: auto;
  }
  .margin-md-right-auto {
    margin-right: auto;
  }
}

@include bp(large) {
  .margin-lg-center {
    margin: 0 auto;
  }
  .margin-lg-left-auto {
    margin-left: auto;
  }
  .margin-lg-right-auto {
    margin-right: auto;
  }
}

$ways: 'top' 'right' 'bottom' 'left';
@each $item in $ways {
  .margin-#{$item}-0 {
    margin-#{$item}: 0 !important;
  }

  .margin-#{$item}-xs-0 {
    @include bp(xsmall) {
      margin-#{$item}: 0 !important;
    }
  }

  .margin-#{$item}-sm-0 {
    @include bp(small) {
      margin-#{$item}: 0 !important;
    }
  }

  .margin-#{$item}-md-0 {
    @include bp(medium) {
      margin-#{$item}: 0 !important;
    }
  }

  .margin-#{$item}-lg-0 {
    @include bp(large) {
      margin-#{$item}: 0 !important;
    }
  }

  @include bp(rev-large) {
    .margin-bp-#{$item}-lg-0 {
      margin-#{$item}: 0 !important;
    }
  }

  @include bp(rev-medium) {
    .margin-bp-#{$item}-md-0 {
      margin-#{$item}: 0 !important;
    }
  }

  @include bp(rev-small) {
    .margin-bp-#{$item}-sm-0 {
      margin-#{$item}: 0 !important;
    }
  }

  @include bp(rev-xsmall) {
    .margin-bp-#{$item}-xs-0 {
      margin-#{$item}: 0 !important;
    }
  }
}
