.process {
  position: relative;
  @include bp(rev-small) {
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -70px;
      display: block;
      width: 2px;
      height: 100%;
      border: 1px dashed #eaeaea;
      z-index: -1;
    }
  }
}

.process__item {
  position: relative;
  margin-top: 5rem;
  margin-bottom: 10rem;
  &:last-child {
    margin-bottom: 0;
  }
  @include bp(small) {
    position: static;
    margin: 0;
  }
}

.process__image {
}

.process__dot {
  position: absolute;
  top: -90px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  @include bp(small) {
    top: 50%;
  }
  @include bp(rev-small) {
    left: 50%;
    transform: translateX(-50%);
  }
  &:hover {
    &:after {
      background-color: $color-red;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    transform: translate(-50%, -50%);
    border: 2px solid $color-red;
    background-color: $color-white;
    border-radius: 100%;
    @include bp(rev-small) {
      background-color: $color-red;
    }
  }
  &.is-active {
    &:after {
      background-color: $color-red;
    }
    & + .process__content {
      @include bp(small) {
        opacity: 1;
        visibility: visible;
        padding: calculate-rem(50px) calculate-rem(36px);
        filter: drop-shadow(0 5px 30px rgba(175, 175, 175, 0.3));
        background-color: $color-white;
        transform: translateY(0);
        &.process__content--center {
          transform: translate(-50%, 0);
        }
      }
    }
  }
}

.process__dot--1 {
  @include bp(small) {
    left: 0;
    transform: translate(-50%, -50%);
  }
}

.process__dot--2 {
  @include bp(small) {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.process__dot--3 {
  @include bp(small) {
    right: -20px;
    transform: translate(0, -50%);
  }
}

.process__content {
  cursor: default;
  @include bp(rev-small) {
    padding: calculate-rem(50px) calculate-rem(36px);
    filter: drop-shadow(0 5px 30px rgba(175, 175, 175, 0.3));
    background-color: $color-white;
  }
  @include bp(small) {
    position: absolute;
    top: calc(100% - 30px);
    opacity: 0;
    visibility: hidden;
    max-width: 430px;
    transform: translateY(20px);
    transition: transform 250ms ease-out, opacity 250ms ease-out;
    will-change: transform, opacity;
    &.process__content--center {
      transform: translate(-50%, 20px);
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: -17px;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 17px 18px 17px;
    border-color: transparent transparent #ffffff transparent;
    @include bp(rev-small) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.process__content--left {
  @include bp(small) {
    left: -52px;
    &:after {
      left: 35px;
    }
  }
}

.process__content--center {
  @include bp(small) {
    left: 50%;
    transform: translateX(-50%);
    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.process__content--right {
  @include bp(small) {
    right: -45px;
    &:after {
      right: 35px;
    }
  }
}

.process-list {
  position: relative;
  padding-top: 2rem;
  list-style: none;
  padding-left: 0.5rem;
  &:after {
    content: '';
    position: absolute;
    left: 0.5rem;
    top: 5px;
    display: block;
    width: 2px;
    height: calc(100% - 15px);
    background-color: $color-gray-light;
  }
}

.process-list__item {
  position: relative;
  padding-left: 2.5rem;
  @include font-size(14px);
  margin-bottom: 0.8rem;
  line-height: 1.4;
  &:last-child {
    margin-bottom: 0;
  }
  &:before {
    content: '';
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 6px;
    height: 6px;
    background-color: $color-red;
    border-radius: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 15px;
    height: 2px;
    background-color: $color-gray-light;
  }
}
