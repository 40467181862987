/* Aspect ratio */

[class*='aspect-ratio-'] {
	display: block;
	position: relative;
}

[class*='aspect-ratio-'] > img {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.aspect-ratio-wide {
	padding-top: 56.25%;
}

.aspect-ratio-square {
	padding-top: 100%;
}