/* Color helpers */
.bg-black {
  background-color: $color-black;
}

.bg-white {
  background-color: $color-white;
}

.bg-purple {
  background-color: $color-red;
}

.bg-gray-light {
  background-color: $color-gray-light;
}

.txt-white {
  color: $color-white;
}

.txt-black {
  color: $color-black;
}

.h--white {
  h1,
  .alfa,
  h2,
  .beta,
  h3,
  .gamma,
  h4,
  .delta,
  h5,
  .epsilon,
  h6,
  .zeta {
    color: $color-white;
  }
}

.h--purple {
  h1,
  .alfa,
  h2,
  .beta,
  h3,
  .gamma,
  h4,
  .delta,
  h5,
  .epsilon,
  h6,
  .zeta {
    color: $color-red;
  }
}

.p--white {
  p {
    color: $color-white;
  }
}

.p--black {
  p {
    color: $color-black;
  }
}

.p--red {
  p {
    color: $color-red;
  }
}

ul.list-style.ul--white,
.ul--white {
  li {
    color: $color-white;
    &:before {
      background-color: $color-white;
    }
  }
}

ol.list-style.ol-white,
.ol-white {
  li {
    color: $color-white;
    &:before {
      color: $color-white;
    }
  }
}
