/* language block */

.language {
  margin-right: -0.5rem;
}

.language__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  color: $color-white;
  font-family: $font-primary-light;
  font-weight: 300;
  text-transform: uppercase;
  &:after {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    height: 3px;
    width: 0;
    transition: width 250ms ease-out;
    background-color: $color-red;
  }
  &:focus,
  &:hover {
    &:after {
      width: 30px;
    }
  }
  &.is-active {
    font-family: $font-primary-medium;
    font-weight: 500;
    &:after {
      width: 30px;
    }
  }
}
