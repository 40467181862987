/* Map block */

.map {
  width: 100%;
  height: 400px;
  background-color: $color-black;
  position: relative;
  @include bp(small) {
    height: 500px;
  }
}
