/* Footer */

.footer {
  position: relative;
  z-index: 2;
  padding: 4.5rem 0.5rem 3rem;

  background-color: $color-black;

  flex-shrink: 0;
}

.footer-bottom {
}

.footer__item {
}

.footer__title {
  color: $color-white;
  @include font-size(18px);
  font-family: $font-primary-medium;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.6rem;
}

.footer__title--small {
  @include font-size(14px);
}

.footer__copy {
  display: block;
  color: $color-white;
  @include font-size(14px);
  line-height: 1.6;
}

.footer__copy {
}

.footer__list {
  column-count: 2;
  @include bp(xsmall) {
    column-count: 3;
  }
}

.footer__item {
  display: block;
  margin-bottom: 0.2rem;
}

.footer__link {
  display: inline-block;
  line-height: 1.4;
  color: $color-white;
  padding: 0.3rem 0;

  @include font-size(12px);
  @include bp(small) {
    @include font-size(12px);
  }
}
