.offer-list {
  @include bp(rev-xsmall) {
    max-width: 340px;
    margin: 0 auto;
  }
}

.offer-list__item {
  display: flex;
  align-items: center;
  padding: 0.6rem 0;
  padding-right: 1rem;
}

.offer-list__icon {
  width: 30px;
  max-width: 30px;
  flex: 0 0 30px;
  svg {
    fill: $color-red;
    @include font-size(30px);
  }
}

.offer-list__text {
  color: $color-black;
  @include font-size(18px);
  font-family: $font-primary-regular;
  font-weight: 400;
  line-height: 1.4;
  padding-left: calculate-rem(20px);
	flex: 1;
}
