/* Base settings */

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  transition: color 0.15s, background 0.15s, border 0.15s, opacity 0.15s;
}

*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

// ::-moz-selection {
//   color: #fff;
//   background-color: $color-red;
// }

// ::selection {
//   color: #fff;
//   background-color: $color-red;
// }

// ::-webkit-scrollbar {
//   width: 6px;
//   height: 6px;
// }

// ::-webkit-scrollbar-track {
//   border-radius: 0;
//   background: $color-gray-light;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 0;
//   background: $color-red;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background: darken($color-red, 5%);
// }

@-ms-viewport {
  width: device-width;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

figure {
  margin: 0;
}

html {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  font-size: 100%;
  @include bp(xxxxlarge) {
    font-size: 150%;
  }
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 310px;
  margin: 0;
  color: #000;
  background-color: $color-white;
  font-family: $font-primary-regular;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

em,
i,
.italic {
  font-style: italic;
}

strong,
.strong {
  font-weight: 700;
}

small,
.small {
  @include font-size(12px);
}
