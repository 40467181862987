/* Social block */

.social {
  margin-left: -0.5rem;
}

.social__item {
  display: inline-block;
  margin-right: 0.3rem;
  margin-bottom: 0.5rem;
  vertical-align: middle;
  &:last-child {
    margin-right: 0;
  }
}

.social__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  color: $color-white;
  svg {
    @include font-size(29px);
  }
  &:focus,
  &:hover {
    svg {
      fill: $color-red;
    }
  }
}
