.job-list__item {
  position: relative;
  display: inline-flex;
  align-items: center;
  position: relative;
  color: #afafaf;
  margin-right: 1.2rem;
  margin-bottom: 0.5rem;
  @include font-size(14px);
  &:not(:last-child):after {
    content: '';
    position: absolute;
    top: 50%;
    right: -0.8rem;
    display: block;
    width: 4px;
    height: 4px;
    background-color: #afafaf;
    border-radius: 100%;
    transform: translateY(-50%);
  }
}
