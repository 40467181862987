/* Blog block */
.article__item {
  margin-bottom: calculate-rem(30px);
}

.article__item--side {
  margin-bottom: calculate-rem(25px);
  .blog__content {
    display: flex;
  }
  .blog__text-block {
    flex: 1;
    padding-left: calculate-rem(25px);
  }
  .blog__title {
    text-decoration: underline;
    @include font-size(16px);
    font-family: $font-primary-light;
    font-weight: 300;
  }
  .blog__item {
    margin-bottom: 0;
  }
}

.blog__content {
  display: block;
  overflow: hidden;
  background-color: $color-white;

  &:focus,
  &:hover {
    .blog__title {
      color: $color-red;
    }

    img {
      transform: scale(1.05, 1.05);
    }
    .btn-text {
      &:after {
        height: 100%;
        transform: translate(-50%, 6px);
      }
    }
    color: $color-black;
    .btn-icon {
      transform: translateX(5px);
    }
  }
  .btn-gray-outline {
    margin-left: 0.3rem;
    margin-top: -0.3rem;
  }
}

.blog__image-block {
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;

  img {
    transition: transform 400ms ease-out;
    will-change: transform;
  }
}

.blog__number-block {
  position: relative;
  top: -5px;
  width: 30px;
  max-width: 30px;
  flex: 0 0 30px;
  color: $color-gray-light;
  font-family: $font-secondary;
  @include font-size(54px);
  font-weight: 700;
  line-height: 1;
}

.blog__text-block {
  p {
    margin-bottom: 2rem;
  }
}

.blog__list {
}
.blog__item {
  position: relative;
  display: inline-flex;
  margin-bottom: 0.3rem;
  color: #afafaf;
  font-family: $font-primary-regular;
  font-weight: 400;
  @include font-size(14px);
  &:not(:last-child) {
    padding-right: 1.2rem;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0.5rem;
      display: block;
      width: 4px;
      height: 4px;
      background-color: #afafaf;
      border-radius: 100%;
      transform: translateY(-50%);
    }
  }
}

.blog__item--big {
  @include font-size(16px);
  @include bp(small) {
    @include font-size(18px);
  }
}

.blog__item--black {
  color: $color-black;
}

.blog__item--highlighted {
  color: $color-red;
  text-transform: uppercase;
}

.blog__title {
  margin-bottom: 1rem;
}

.blog-fixed {
  @include bp(large) {
    position: fixed;
    right: calc(((100vw - 900px) / 2) - 150px);
    top: 50%;
    max-width: 140px;
    margin-top: -80px;
  }
  @include bp(xlarge) {
    right: calc(((100vw - 900px) / 2) - 180px);
  }
  @include bp(xxxxlarge) {
    right: calc(((100vw - 1400px) / 2) - 180px);
  }
}
