.select2-container {
  z-index: 9;
  &.select2-container--open,
  .select2-container--focus {
    .select2-selection--single,
    .select2-selection--multiple {
      .select2-selection__arrow {
        &:after {
          background-image: url('../images/icons/chevron-up.svg');
        }
      }
    }
  }
  .select2-search--inline .select2-search__field {
    margin-top: 0;
  }

  .select2-search--inline {
    display: block;
    width: 100%;
  }

  .select2-selection--single,
  .select2-selection--multiple {
    width: 100%;
    width: 100%;
    height: 50px;
    padding: 0 40px 1px 1rem;
    padding-bottom: 1px;
    font-family: $font-primary-regular;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0px;
    color: $color-black;
    border: 0;
    outline: none !important;
    background-color: $color-white;
    @include bp(xxxxlarge) {
      min-height: 60px;
    }
    @include font-size(14px);
    @include border-radius(0);
    .select2-selection__rendered {
      padding: 0;
      line-height: 50px;
      color: $color-black;
    }
    .select2-selection__placeholder {
      color: rgba(25, 25, 25, 0.3);
    }
    .select2-selection__arrow {
      position: absolute;
      top: 0;
      right: 0px;
      width: 35px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      &:after {
        content: '' !important;
        width: 11px;
        height: 6px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url('../images/icons/chevron-down.svg');
      }
      b {
        display: none;
      }
    }
  }
}

.select2-container
  .select2-container--focus
  .select2-selection--single
  .select2-selection__arrow:after,
.select2-container.select2-container--open
  .select2-selection--single
  .select2-selection__arrow:after {
  content: '\e91a';
}

.select2-dropdown {
  border: 0;
  border-radius: 0;
  background-color: white;
  .select2-results__options--nested .select2-results__option {
    padding: 0 1rem;
  }

  .select2-results__options {
    max-height: 220px !important;
    .select2-results__option[aria-selected='true'] {
      color: $color-black;
      background-color: lighten($color-red, 20%);
    }
    .select2-results__option {
      padding: 0.6rem 1rem;
      font-family: $font-primary-regular;
      font-weight: 400;
      letter-spacing: 0px;
      color: $color-black;

      @include font-size(14px);
    }
    .select2-results__option--highlighted {
      color: $color-black;
      background-color: lighten($color-red, 20%);
    }
  }
}

.select-medium-dropdown {
  &.select2-dropdown {
    .select2-results__options {
      .select2-results__option {
        font-family: $font-primary-regular;
        font-weight: 700;
        padding: 18px 15px;
        @include font-size(14px);
      }
    }
  }
}
