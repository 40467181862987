/* Web fonts */
@font-face {
  font-family: "Blacker-Pro-ExBd";
  src: url("../fonts/webfonts/Blacker/BlackerProDis-ExBd.woff2") format("woff2"), url("../fonts/webfonts/Blacker/BlackerProDis-ExBd.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-Light";
  src: url("../fonts/webfonts/Gilroy/Gilroy-Light.woff2") format("woff2"), url("../fonts/webfonts/Gilroy/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("../fonts/webfonts/Gilroy/Gilroy-Regular.woff2") format("woff2"), url("../fonts/webfonts/Gilroy/Gilroy-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url("../fonts/webfonts/Gilroy/Gilroy-Medium.woff2") format("woff2"), url("../fonts/webfonts/Gilroy/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("../fonts/webfonts/Gilroy/Gilroy-Bold.woff2") format("woff2"), url("../fonts/webfonts/Gilroy/Gilroy-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* GRID switcher */
.grid-switcher {
  align-items: center;
  background-color: #363636;
  border-radius: 50%;
  bottom: 10px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  justify-content: center;
  left: 10px;
  position: fixed;
  width: 40px;
  height: 40px;
  text-transform: uppercase;
  transition: opacity 0.25s ease-in-out;
  z-index: 99999999;
  font-family: "Gilroy-Regular", Arial, sans-serif;
}

.grid-switcher.is-active, .grid-switcher:focus, .grid-switcher:hover {
  color: #000000;
  background-color: #ffe5e5;
}

.grid-container {
  font-size: 0;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 9999999;
  opacity: 0;
  overflow: hidden;
  transition: opacity 600ms ease;
}

.grid-container.is-active {
  opacity: 1;
  overflow: visible;
  transition: opacity 600ms ease;
}

.grid-column-item {
  width: 100%;
  min-width: 1px;
  min-height: 0;
  width: calc(100% / 6);
  flex: 0 0 calc(100% / 6);
  max-width: calc(100% / 6);
}

@media (min-width: 771px) {
  .grid-column-item {
    width: calc(100% / 12);
    flex: 0 0 calc(100% / 12);
    max-width: calc(100% / 12);
  }
}

.grid-column {
  background-color: rgba(255, 0, 0, 0.1);
  height: 100vh;
}

/* Animate helpers */
@-webkit-keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
  animation-name: spinner-rotate;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border: 10px solid #ff5c53;
  border-right-color: transparent;
  border-radius: 50%;
}

/* Typography */
.typography p,
.typography li,
.typography td,
.typography th {
  line-height: 1.5em;
}

.typography p,
.typography ul,
.typography ol,
.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6,
.typography table,
.typography fieldset,
.typography blockquote,
.typography iframe {
  margin-bottom: 1rem;
}

.typography h1 {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Blacker-Pro-ExBd", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 26px;
  font-size: 1.625rem;
}

@media (min-width: 771px) {
  .typography h1 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

.typography h2 {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Blacker-Pro-ExBd", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 24px;
  font-size: 1.5rem;
}

@media (min-width: 771px) {
  .typography h2 {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

.typography h3 {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Blacker-Pro-ExBd", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 22px;
  font-size: 1.375rem;
}

@media (min-width: 771px) {
  .typography h3 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.typography h4 {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Blacker-Pro-ExBd", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 20px;
  font-size: 1.25rem;
}

@media (min-width: 771px) {
  .typography h4 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.typography h5 {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Blacker-Pro-ExBd", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 18px;
  font-size: 1.125rem;
}

@media (min-width: 771px) {
  .typography h5 {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.typography h6 {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Blacker-Pro-ExBd", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 18px;
  font-size: 1.125rem;
}

@media (min-width: 771px) {
  .typography h6 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.typography a {
  text-decoration: underline;
  color: #ff5c53;
}

.typography a:focus, .typography a:hover {
  text-decoration: underline;
}

.typography p {
  display: block;
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #030303;
  font-size: 16px;
  font-size: 1rem;
}

.typography strong {
  font-family: "Gilroy-Bold", Arial, sans-serif;
  font-weight: 700;
  color: #030303;
}

.typography img {
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
}

.typography ul,
.typography ol {
  margin-bottom: 2.5rem;
  padding-left: 0;
  list-style: none;
}

.typography ul li,
.typography ol li {
  position: relative;
  margin-bottom: 0.3rem;
  padding-left: 1.5rem;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #000000;
  font-size: 16px;
  font-size: 1rem;
}

.typography ul li:before {
  position: absolute;
  top: 13px;
  left: 3px;
  display: block;
  width: 6px;
  height: 1px;
  content: '';
  color: #000000;
  border-radius: 100%;
  background-color: #000000;
}

.typography ol {
  list-style-position: inside;
  counter-reset: item;
}

.typography ol li:before {
  position: absolute;
  top: 1px;
  left: 0;
  display: inline-block;
  line-height: 1.4;
  content: counter(item) ".";
  counter-increment: item;
  color: #000000;
  font-size: 16px;
  font-size: 1rem;
}

.typography figure {
  margin: 0;
  margin-bottom: 2rem;
}

.typography figure img {
  margin: 0;
}

.typography figcaption {
  margin-top: 0.6rem;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  font-size: 0.75rem;
}

/* Form base */
.form__input, .form__textarea {
  width: 100%;
  min-height: 50px;
  padding: 0.2rem 1rem;
  border: 0;
  background-color: #ffffff;
  color: #000000;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  border-radius: 0;
  outline: none !important;
  -webkit-appearance: none;
}

.form__input:placeholder-shown, .form__textarea:placeholder-shown {
  border-color: #000000;
}

.form__input:focus, .form__textarea:focus {
  border-color: #000000;
}

.form__input--border-bottom {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border-bottom: 1px solid #000000;
}

.form__textarea {
  height: 165px;
  padding: 1rem 1.5rem;
  line-height: 1.5rem;
  resize: none;
  border-radius: 0;
}

@media (min-width: 576px) {
  .form__textarea--limit {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    resize: none;
    min-height: 160px;
    max-height: 250px;
  }
}

@media (max-width: 575px) {
  .form__textarea--limit {
    height: 200px !important;
  }
}

.form__select {
  width: 100%;
  height: 50px;
  padding: 0.2rem 1rem;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.2;
  color: #000000;
  border: 0;
  outline: none;
  background-color: #ffffff;
  font-size: 16px;
  font-size: 1rem;
  -webkit-appearance: value;
  -moz-appearance: value;
  -ms-appearance: value;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.form__icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 50px;
  height: 50px;
  font-size: 22px;
  font-size: 1.375rem;
  color: #000000;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.form__icon-block .form__input {
  padding-right: 50px;
}

.form__label {
  display: block;
  margin: 0 0 10px;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  color: rgba(25, 25, 25, 0.3);
  font-size: 16px;
  font-size: 1rem;
}

.form__field {
  position: relative;
}

.form__field--small {
  margin-bottom: 0.5rem;
}

.form__field--medium {
  margin-bottom: 1rem;
}

.form__field--big {
  margin-bottom: 2rem;
}

.form__placeholder {
  position: relative;
  z-index: 1;
}

.form__placeholder.is-active .form__label {
  width: calc(100% - 20px);
  transform: translateX(0) translateY(-1px);
  font-size: 12px;
  font-size: 0.75rem;
  padding: 5px 1rem 5px;
}

.form__placeholder .form__input,
.form__placeholder .form__textarea {
  padding: 25px 1rem 13px;
}

.form__placeholder .select2-container .select2-selection--single {
  padding: 7px 40px 0 1rem;
}

.form__placeholder .form__label {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  margin: 0;
  cursor: text;
  transition: all 0.3s ease;
  transform: translateX(0) translateY(4px);
  text-transform: none;
  pointer-events: none;
  padding: 10px 1rem 10px;
}

.form__placeholder--no-padding.is-active .form__label {
  transform: translateX(0) translateY(-1px);
  padding: 5px 0 5px;
}

.form__placeholder--no-padding .form__input,
.form__placeholder--no-padding .form__textarea {
  padding: 25px 0 13px;
}

.form__placeholder--no-padding .form__label {
  transform: translateX(0) translateY(4px);
  padding: 10px 0 10px;
}

.form__placeholder-textarea .form__label {
  top: 1px;
  left: 2px;
  background: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Placeholder helpers */
input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(25, 25, 25, 0.3);
}

input::-moz-input-placeholder,
select::-moz-input-placeholder,
textarea::-moz-input-placeholder {
  color: rgba(25, 25, 25, 0.3);
}

input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgba(25, 25, 25, 0.3);
}

.form__field--white input::-webkit-input-placeholder,
.form__field--white select::-webkit-input-placeholder,
.form__field--white textarea::-webkit-input-placeholder {
  color: white;
}

.form__field--white input::-moz-input-placeholder,
.form__field--white select::-moz-input-placeholder,
.form__field--white textarea::-moz-input-placeholder {
  color: white;
}

.form__field--white input:-ms-input-placeholder,
.form__field--white select:-ms-input-placeholder,
.form__field--white textarea:-ms-input-placeholder {
  color: white;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
  opacity: 1;
}

input:focus::-moz-input-placeholder,
textarea:focus::-moz-input-placeholder {
  color: transparent;
  opacity: 1;
}

input:focus:-moz-input-placeholder,
textarea:focus:-moz-input-placeholder {
  color: transparent;
  opacity: 1;
}

/* Form validation */
.has-field-error .form__field-message {
  display: block;
}

.has-field-error.select2-container .select2-selection--single,
.has-field-error.select-classic .select2-container .select2-selection--multiple, .has-field-error.form__textarea, .has-field-error.form__input {
  border-color: #ea4335 !important;
}

.has-field-error + .select2-container .select2-selection--single,
.has-field-error + .select-classic .select2-container .select2-selection--multiple {
  border-color: #ea4335 !important;
}

.has-field-success.select2-container .select2-selection--single,
.has-field-success.select-classic .select2-container .select2-selection--multiple, .has-field-success.form__textarea, .has-field-success.form__input {
  border-color: #55a954 !important;
}

.has-field-success + .select2-container .select2-selection--single,
.has-field-success + .select-classic .select2-container .select2-selection--multiple {
  border-color: #55a954 !important;
}

.parsley-error.select2-container .select2-selection--single,
.parsley-error.select-classic .select2-container .select2-selection--multiple, .parsley-error.form__textarea, .parsley-error.form__input,
.has-error.select2-container .select2-selection--single,
.has-error.select-classic .select2-container .select2-selection--multiple,
.has-error.form__textarea,
.has-error.form__input {
  border-color: #ea4335 !important;
}

.parsley-error + .select2-container .select2-selection--single,
.parsley-error + .select-classic .select2-container .select2-selection--multiple,
.has-error + .select2-container .select2-selection--single,
.has-error + .select-classic .select2-container .select2-selection--multiple {
  border-color: #ea4335 !important;
}

.has-field-success.select2-container .select2-selection--single,
.has-field-success.select-classic .select2-container .select2-selection--multiple, .has-field-success.form__textarea, .has-field-success.form__input,
.parsley-success.select2-container .select2-selection--single,
.parsley-success.select-classic .select2-container .select2-selection--multiple,
.parsley-success.form__textarea,
.parsley-success.form__input {
  border-color: #55a954 !important;
}

.has-field-success + .select2-container .select2-selection--single,
.has-field-success + .select-classic .select2-container .select2-selection--multiple,
.parsley-success + .select2-container .select2-selection--single,
.parsley-success + .select-classic .select2-container .select2-selection--multiple {
  border-color: #55a954 !important;
}

.form__field-message {
  display: none;
  margin-top: 5px;
  color: #ea4335;
  text-align: right;
  font-size: 12px;
  font-size: 0.75rem;
}

.parsley-errors-list {
  color: #ea4335;
  text-align: right;
  font-size: 12px;
  font-size: 0.75rem;
}

.parsley-errors-list li {
  display: block;
  margin-top: 5px;
}

/* .checkbox.checkbox--radiobox
	input#checkbox_payment_1.checkbox__input-hidden(type='radio', name="payment" required='')
	label.checkbox__label(for='checkbox_payment_1')
		span.checkbox__box
		span.checkbox__text Platba kartou */
/* Checkbox helpers */
.checkbox {
  margin-bottom: 2rem;
}

.input-hidden,
.checkbox__input-hidden {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.checkbox__label {
  margin: 0;
  padding-left: 0;
  min-height: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding-top: 0;
  cursor: pointer;
  text-transform: none;
}

.checkbox__box {
  position: relative;
  max-width: 18px;
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  border: 1px solid #000000;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}

.checkbox__text {
  max-width: calc(100% - 18px);
  flex: 0 0 calc(100% - 18px);
  width: calc(100% - 18px);
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  cursor: pointer;
  text-align: left;
  color: #000000;
  font-size: 12px;
  font-size: 0.75rem;
  padding-left: 0.6rem;
  line-height: 1.2;
}

.checkbox__slider {
  position: absolute;
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  border-radius: 50% !important;
  background-color: #000000;
  transition: all 0.2s ease;
}

.checkbox__input-hidden:checked + .checkbox__label .checkbox__box:after {
  content: '';
  display: block;
  position: absolute;
  top: 1px;
  left: 5px;
  width: 6px;
  height: 11px;
  border: solid #000000;
  border-width: 0 2px 2px 0;
  transform: rotate(40deg);
}

.checkbox--switcher {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 26px;
}

.checkbox--switcher .checkbox__label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #000000;
  border-radius: 35px;
  background-color: transparent;
}

.checkbox--switcher .checkbox__input-hidden:checked + .checkbox__label {
  border-color: #ff5c53;
}

.checkbox--switcher .checkbox__input-hidden:checked + .checkbox__label:after {
  display: none;
}

.checkbox--switcher .checkbox__input-hidden:checked + .checkbox__label .checkbox__slider {
  background-color: #ff5c53;
  left: calc(100% - 20px);
}

.checkbox--radiobox {
  margin-bottom: 2rem;
}

.checkbox--radiobox .checkbox__box {
  top: 0;
  max-width: 20px;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  border: 2px solid #000000;
  border-radius: 100%;
}

.checkbox--radiobox .checkbox__input-hidden:checked + .checkbox__label .checkbox__box:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  margin-top: -5px;
  margin-left: -5px;
  width: 10px;
  height: 10px;
  background-color: #000000;
}

/* Buttons helper */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  padding: 0.6em 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  outline: none !important;
  background: #000000;
  text-align: center;
  font-family: "Gilroy-Medium", Arial, sans-serif;
  transition: background-color 250ms ease;
  border-radius: 0;
  font-size: 18px;
  font-size: 1.125rem;
  -webkit-appearance: none;
}

.btn, .btn:visited, .btn:active, .btn:focus {
  color: white;
}

.btn:hover {
  transition: background-color 250ms ease;
}

@media (min-width: 992px) {
  .btn:hover {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }
}

.btn--responsive {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
}

.btn--font-size-xsmall {
  font-size: 12px;
  font-size: 0.75rem;
}

.btn--font-size-small {
  font-size: 14px;
  font-size: 0.875rem;
}

.btn--font-size-medium {
  font-size: 16px;
  font-size: 1rem;
}

.btn--font-size-big {
  font-size: 18px;
  font-size: 1.125rem;
}

.btn--font-size-large {
  font-size: 20px;
  font-size: 1.25rem;
}

.btn--size-small {
  padding: 0.6em 1.2rem;
}

.btn--size-medium {
  padding: 1.2em 1.5rem;
}

.btn--size-big {
  padding: 2.2em 2rem;
}

.btn--min-width-medium {
  min-width: 140px;
}

.btn-icon {
  margin-left: 1rem;
}

.btn-black-fill {
  color: #ffffff;
  border: 2px solid transparent;
  background-color: #000000;
}

.btn-black-fill, .btn-black-fill:visited, .btn-black-fill:active, .btn-black-fill:focus {
  color: #ffffff;
}

.btn-black-fill:hover {
  color: #ffffff;
  background-color: #ff1c0f;
}

.btn-red-fill {
  color: #ffffff;
  border: 2px solid transparent;
  background-color: #ff5c53;
}

.btn-red-fill, .btn-red-fill:visited, .btn-red-fill:active, .btn-red-fill:focus {
  color: #ffffff;
}

.btn-red-fill:hover {
  color: #ffffff;
  background-color: #ff1c0f;
}

.btn-red-fill:hover .btn__icon {
  color: #ffffff;
}

.btn-red-fill .btn__icon {
  color: #ffffff;
}

.btn-gray-outline {
  padding: 0;
  color: #000000;
  background-color: transparent;
  margin-left: -0.5rem;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
}

.btn-gray-outline .btn-text {
  padding: 0.4em 0.6rem;
  position: relative;
  z-index: 1;
}

.btn-gray-outline .btn-text:after {
  content: '';
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 100%;
  height: 7px;
  background-color: #f8f8f8;
  z-index: -1;
  transition: all 250ms ease-out;
}

.btn-gray-outline .btn-icon {
  display: inline-block;
  top: 3px;
  position: relative;
  transition: transform 250ms ease-out;
}

.btn-gray-outline, .btn-gray-outline:visited, .btn-gray-outline:active, .btn-gray-outline:focus {
  color: #000000;
}

.btn-gray-outline:hover {
  color: #000000;
}

.btn-gray-outline:hover .btn-text:after {
  height: 100%;
  transform: translate(-50%, 6px);
}

.btn-gray-outline:hover .btn-icon {
  transform: translateX(5px);
}

.btn-gray-outline--white .btn-text:after {
  background-color: #ffffff;
}

/* Color helpers */
.bg-black {
  background-color: #000000;
}

.bg-white {
  background-color: #ffffff;
}

.bg-purple {
  background-color: #ff5c53;
}

.bg-gray-light {
  background-color: #f8f8f8;
}

.txt-white {
  color: #ffffff;
}

.txt-black {
  color: #000000;
}

.h--white h1,
.h--white .alfa,
.h--white h2,
.h--white .beta,
.h--white h3,
.h--white .gamma,
.h--white h4,
.h--white .delta,
.h--white h5,
.h--white .epsilon,
.h--white h6,
.h--white .zeta {
  color: #ffffff;
}

.h--purple h1,
.h--purple .alfa,
.h--purple h2,
.h--purple .beta,
.h--purple h3,
.h--purple .gamma,
.h--purple h4,
.h--purple .delta,
.h--purple h5,
.h--purple .epsilon,
.h--purple h6,
.h--purple .zeta {
  color: #ff5c53;
}

.p--white p {
  color: #ffffff;
}

.p--black p {
  color: #000000;
}

.p--red p {
  color: #ff5c53;
}

ul.list-style.ul--white li,
.ul--white li {
  color: #ffffff;
}

ul.list-style.ul--white li:before,
.ul--white li:before {
  background-color: #ffffff;
}

ol.list-style.ol-white li,
.ol-white li {
  color: #ffffff;
}

ol.list-style.ol-white li:before,
.ol-white li:before {
  color: #ffffff;
}

/* Gutter helpers */
.no-gutter {
  margin: 0;
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.gutter-4.row {
  margin-right: -2px;
  margin-left: -2px;
}

.gutter-4 > [class*='col-'],
.gutter-4 > [class*=' col-'] {
  padding-right: 2px;
  padding-left: 2px;
}

.gutter-6.row {
  margin-right: -3px;
  margin-left: -3px;
}

.gutter-6 > [class*='col-'],
.gutter-6 > [class*=' col-'] {
  padding-right: 3px;
  padding-left: 3px;
}

.gutter-10.row {
  margin-right: -5px;
  margin-left: -5px;
}

.gutter-10 > [class*='col-'],
.gutter-10 > [class*=' col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.gutter-20.row {
  margin-right: -10px;
  margin-left: -10px;
}

.gutter-20 > [class*='col-'],
.gutter-20 > [class*=' col-'] {
  padding-right: 10px;
  padding-left: 10px;
}

.gutter-30.row {
  margin-right: -15px;
  margin-left: -15px;
}

.gutter-30 > [class*='col-'],
.gutter-30 > [class*=' col-'] {
  padding-right: 15px;
  padding-left: 15px;
}

.gutter-40.row {
  margin-right: -20px;
  margin-left: -20px;
}

.gutter-40 > [class*='col-'],
.gutter-40 > [class*=' col-'] {
  padding-right: 20px;
  padding-left: 20px;
}

.gutter-50.row {
  margin-right: -25px;
  margin-left: -25px;
}

.gutter-50 > [class*='col-'],
.gutter-50 > [class*=' col-'] {
  padding-right: 25px;
  padding-left: 25px;
}

.gutter-60.row {
  margin-right: -30px;
  margin-left: -30px;
}

.gutter-60 > [class*='col-'],
.gutter-60 > [class*=' col-'] {
  padding-right: 30px;
  padding-left: 30px;
}

.gutter-sm-80.row {
  margin-right: -40px;
  margin-left: -40px;
}

.gutter-sm-80 > [class*='col-'],
.gutter-sm-80 > [class*=' col-'] {
  padding-right: 40px;
  padding-left: 40px;
}

.gutter-sm-100.row {
  margin-right: -50px;
  margin-left: -50px;
}

.gutter-sm-100 > [class*='col-'],
.gutter-sm-100 > [class*=' col-'] {
  padding-right: 50px;
  padding-left: 50px;
}

@media (min-width: 576px) {
  .gutter-xs-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-xs-10 > [class*='col-'],
  .gutter-xs-10 > [class*=' col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-xs-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-xs-20 > [class*='col-'],
  .gutter-xs-20 > [class*=' col-'] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-xs-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-xs-30 > [class*='col-'],
  .gutter-xs-30 > [class*=' col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-xs-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-xs-40 > [class*='col-'],
  .gutter-xs-40 > [class*=' col-'] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-xs-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-xs-50 > [class*='col-'],
  .gutter-xs-50 > [class*=' col-'] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-xs-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-xs-60 > [class*='col-'],
  .gutter-xs-60 > [class*=' col-'] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-xs-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-xs-100 > [class*='col-'],
  .gutter-xs-100 > [class*=' col-'] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 771px) {
  .gutter-sm-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-sm-10 > [class*='col-'],
  .gutter-sm-10 > [class*=' col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-sm-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-sm-20 > [class*='col-'],
  .gutter-sm-20 > [class*=' col-'] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-sm-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-sm-30 > [class*='col-'],
  .gutter-sm-30 > [class*=' col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-sm-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-sm-40 > [class*='col-'],
  .gutter-sm-40 > [class*=' col-'] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-sm-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-sm-50 > [class*='col-'],
  .gutter-sm-50 > [class*=' col-'] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-sm-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-sm-60 > [class*='col-'],
  .gutter-sm-60 > [class*=' col-'] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-sm-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-sm-100 > [class*='col-'],
  .gutter-sm-100 > [class*=' col-'] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 992px) {
  .no-md-gutter {
    margin: 0;
  }
  .no-md-gutter > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
  .gutter-md-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-md-10 > [class*='col-'],
  .gutter-md-10 > [class*=' col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-md-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-md-20 > [class*='col-'],
  .gutter-md-20 > [class*=' col-'] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-md-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-md-30 > [class*='col-'],
  .gutter-md-30 > [class*=' col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-md-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-md-40 > [class*='col-'],
  .gutter-md-40 > [class*=' col-'] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-md-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-md-50 > [class*='col-'],
  .gutter-md-50 > [class*=' col-'] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-md-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-md-60 > [class*='col-'],
  .gutter-md-60 > [class*=' col-'] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-md-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-md-100 > [class*='col-'],
  .gutter-md-100 > [class*=' col-'] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 1200px) {
  .gutter-lg-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-lg-10 > [class*='col-'],
  .gutter-lg-10 > [class*=' col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-lg-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-lg-20 > [class*='col-'],
  .gutter-lg-20 > [class*=' col-'] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-lg-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-lg-30 > [class*='col-'],
  .gutter-lg-30 > [class*=' col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-lg-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-lg-40 > [class*='col-'],
  .gutter-lg-40 > [class*=' col-'] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-lg-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-lg-50 > [class*='col-'],
  .gutter-lg-50 > [class*=' col-'] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-lg-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-lg-60 > [class*='col-'],
  .gutter-lg-60 > [class*=' col-'] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-lg-80.row {
    margin-right: -40px;
    margin-left: -40px;
  }
  .gutter-lg-80 > [class*='col-'],
  .gutter-lg-80 > [class*=' col-'] {
    padding-right: 40px;
    padding-left: 40px;
  }
  .gutter-lg-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-lg-100 > [class*='col-'],
  .gutter-lg-100 > [class*=' col-'] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 1440px) {
  .gutter-xl-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-xl-30 > [class*='col-'],
  .gutter-xl-30 > [class*=' col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-xl-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-xl-40 > [class*='col-'],
  .gutter-xl-40 > [class*=' col-'] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-xl-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-xl-60 > [class*='col-'],
  .gutter-xl-60 > [class*=' col-'] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-xl-80.row {
    margin-right: -40px;
    margin-left: -40px;
  }
  .gutter-xl-80 > [class*='col-'],
  .gutter-xl-80 > [class*=' col-'] {
    padding-right: 40px;
    padding-left: 40px;
  }
  .gutter-xl-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-xl-100 > [class*='col-'],
  .gutter-xl-100 > [class*=' col-'] {
    padding-right: 50px;
    padding-left: 50px;
  }
  .gutter-xl-120.row {
    margin-right: -60px;
    margin-left: -60px;
  }
  .gutter-xl-120 > [class*='col-'],
  .gutter-xl-120 > [class*=' col-'] {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@media (min-width: 1600px) {
  .gutter-xxl-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-xxl-30 > [class*='col-'],
  .gutter-xxl-30 > [class*=' col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-xxl-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-xxl-60 > [class*='col-'],
  .gutter-xxl-60 > [class*=' col-'] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-xxl-120.row {
    margin-right: -60px;
    margin-left: -60px;
  }
  .gutter-xxl-120 > [class*='col-'],
  .gutter-xxl-120 > [class*=' col-'] {
    padding-right: 60px;
    padding-left: 60px;
  }
  .gutter-xxl-180.row {
    margin-right: -90px;
    margin-left: -90px;
  }
  .gutter-xxl-180 > [class*='col-'],
  .gutter-xxl-180 > [class*=' col-'] {
    padding-right: 90px;
    padding-left: 90px;
  }
}

@media (min-width: 1800px) {
  .gutter-xxxl-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-xxxl-30 > [class*='col-'],
  .gutter-xxxl-30 > [class*=' col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-xxxl-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-xxxl-60 > [class*='col-'],
  .gutter-xxxl-60 > [class*=' col-'] {
    padding-right: 30px;
    padding-left: 30px;
  }
}

/* Hidden content */
.hidden-content {
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: opacity 250ms ease;
}

.hidden-content.is-active {
  height: auto;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  transition: opacity 250ms ease;
}

@media (max-width: 575px) {
  .hidden-xs-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 250ms ease;
  }
  .hidden-xs-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transition: opacity 250ms ease;
  }
}

@media (max-width: 770px) {
  .hidden-sm-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 250ms ease;
  }
  .hidden-sm-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transition: opacity 250ms ease;
  }
}

@media (max-width: 991px) {
  .hidden-md-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 250ms ease;
  }
  .hidden-md-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transition: opacity 250ms ease;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 250ms ease;
  }
  .hidden-lg-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transition: opacity 250ms ease;
  }
}

/* Hidden class */
.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-visible {
  overflow: visible;
}

@media (max-width: 770px) {
  .overflow-x-hidden-sm {
    overflow-x: hidden;
  }
}

/* HR helper */
.hr-line {
  position: relative;
  margin: 30px 0;
  height: 1px;
  background-color: #f8f8f8;
}

.hr-line-1 {
  margin: 1rem 0;
}

.hr-line-1-3 {
  margin: 1rem 3rem;
}

.hr-line-2 {
  margin: 2rem 0;
}

.hr-line-3 {
  margin: 3rem 0;
}

.hr-line--width-100 {
  width: 100px;
}

.hr-line--width-200 {
  width: 200px;
}

.hr-line--width-300 {
  width: 300px;
}

.hr-line--height-2 {
  height: 2px;
}

.hr-line--center {
  margin: 0 auto;
}

.hr-line--white {
  background-color: #ffffff;
}

.hr-line--gray {
  background-color: #f8f8f8;
}

/* Icon helpers */
.icon-svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon--size-12 svg {
  font-size: 12px;
  font-size: 0.75rem;
}

.icon--size-14 svg {
  font-size: 14px;
  font-size: 0.875rem;
}

.icon--size-16 svg {
  font-size: 16px;
  font-size: 1rem;
}

.icon--size-18 svg {
  font-size: 18px;
  font-size: 1.125rem;
}

.icon--size-20 svg {
  font-size: 20px;
  font-size: 1.25rem;
}

.icon--size-22 svg {
  font-size: 22px;
  font-size: 1.375rem;
}

.icon--size-24 svg {
  font-size: 24px;
  font-size: 1.5rem;
}

.icon--size-26 svg {
  font-size: 26px;
  font-size: 1.625rem;
}

.icon--size-28 svg {
  font-size: 28px;
  font-size: 1.75rem;
}

.icon--size-42 svg {
  font-size: 42px;
  font-size: 2.625rem;
}

.icon--size-60 svg {
  font-size: 60px;
  font-size: 3.75rem;
}

.icon---white svg {
  fill: #ffffff;
}

.icon---black svg {
  fill: #000000;
}

.icon---red svg {
  fill: #ff5c53;
}

.icon--offset-top {
  position: relative;
  top: 3px;
}

/* Image helpers */
.img {
  display: block;
}

.img--full {
  width: 100%;
}

.img--responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img--cover {
  height: 100%;
  object-fit: cover;
}

.img--contain {
  height: 100%;
  object-fit: contain;
}

.img--shadow {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.bg-image-hover,
.img-hover {
  display: block;
  overflow: hidden;
}

.bg-image-hover:focus > img, .bg-image-hover:hover > img,
.img-hover:focus > img,
.img-hover:hover > img {
  transform: scale(1.05, 1.05);
}

.bg-image-hover img,
.img-hover img {
  transition: transform 400ms ease-out;
  will-change: transform;
}

.img-flex-contain,
.img-flex-cover {
  display: flex;
}

.img-flex-contain img,
.img-flex-cover img {
  display: block;
  width: 100%;
  height: 100%;
}

.img-flex-cover img {
  object-fit: cover;
}

.img-flex-contain img {
  object-fit: contain;
}

.img-flex-client {
  height: 100px;
}

.img-flex-article {
  height: 240px;
}

.bg-image,
.bg-rectangle {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-image-logo-our-team {
  left: auto;
  right: -40px;
  bottom: 180px;
  top: auto;
  width: auto;
  height: auto;
}

@media (min-width: 576px) {
  .bg-image-logo-our-team {
    right: -80px;
  }
}

.bg-image-logo-our-team svg {
  fill: #f8f8f8;
  font-size: 200px;
  font-size: 12.5rem;
}

@media (min-width: 576px) {
  .bg-image-logo-our-team svg {
    font-size: 310px;
    font-size: 19.375rem;
  }
}

.bg-image-logo-how-works {
  left: -30px;
  top: auto;
  bottom: -80px;
  width: auto;
  height: auto;
}

@media (min-width: 576px) {
  .bg-image-logo-how-works {
    left: -60px;
    bottom: -40px;
  }
}

.bg-image-logo-how-works svg {
  fill: #f8f8f8;
  font-size: 160px;
  font-size: 10rem;
}

@media (min-width: 576px) {
  .bg-image-logo-how-works svg {
    font-size: 310px;
    font-size: 19.375rem;
  }
}

.bg-image-our-team {
  width: 100vw;
  right: auto;
  left: calc(-50vw + 50%);
  background-color: #f8f8f8;
  object-fit: cover;
  z-index: -2;
  position: relative;
}

@media (min-width: 771px) {
  .bg-image-our-team {
    width: calc(100vw - 44vw);
    left: auto;
    right: calc(-50vw + 100%);
    position: absolute;
  }
}

.bg-image-logo-process {
  left: auto;
  top: auto;
  bottom: -80px;
  width: auto;
  height: auto;
}

@media (min-width: 576px) {
  .bg-image-logo-process {
    right: -40px;
    bottom: -80px;
  }
}

.bg-image-logo-process svg {
  fill: #f8f8f8;
  font-size: 160px;
  font-size: 10rem;
}

@media (min-width: 576px) {
  .bg-image-logo-process svg {
    font-size: 310px;
    font-size: 19.375rem;
  }
}

.bg-image-logo-position {
  left: auto;
  right: -40px;
  top: 200px;
  width: auto;
  height: auto;
}

@media (min-width: 576px) {
  .bg-image-logo-position {
    top: 100px;
  }
}

@media (min-width: 771px) {
  .bg-image-logo-position {
    right: auto;
    left: -120px;
    top: 500px;
  }
}

.bg-image-logo-position svg {
  fill: #f8f8f8;
  font-size: 200px;
  font-size: 12.5rem;
}

@media (min-width: 576px) {
  .bg-image-logo-position svg {
    font-size: 310px;
    font-size: 19.375rem;
  }
}

.bg-rectangle-our-team {
  width: 100vw;
  left: calc(-50vw + 50%);
  background-color: #f8f8f8;
  z-index: -1;
}

@media (min-width: 771px) {
  .bg-rectangle-our-team {
    width: calc(100vw - 50vw);
    left: calc(-50vw + 100%);
  }
}

.bg-rectangle-work {
  width: 100vw;
  left: calc(-50vw + 50%);
  background-color: #f8f8f8;
  z-index: -1;
  display: none;
}

@media (min-width: 771px) {
  .bg-rectangle-work {
    display: block;
    left: auto;
    width: calc(100vw - 55vw);
    right: calc(-50vw + 50%);
    height: 590px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.bg-rectangle-homepage-blog {
  top: 0;
  left: auto;
  right: 0;
  height: 425px;
  background-color: #f8f8f8;
  display: none;
}

@media (min-width: 771px) {
  .bg-rectangle-homepage-blog {
    display: block;
    top: 70px;
    height: 435px;
    width: calc(((100% - 730px) / 2) + (730px / 2) - 30px);
  }
}

@media (min-width: 992px) {
  .bg-rectangle-homepage-blog {
    width: calc(((100% - 950px) / 2) + ((950px * 2) / 3) - 25px);
    height: 441px;
  }
}

@media (min-width: 1200px) {
  .bg-rectangle-homepage-blog {
    width: calc(((100% - 1130px) / 2) + ((1130px * 2) / 3) - 25px);
  }
}

@media (max-width: 770px) {
  .img-group-negative-sm {
    margin: 0 -23px;
  }
}

@media (max-width: 770px) {
  .negative-offset-sm {
    margin: 0 -23px;
  }
}

@media (max-width: 770px) {
  .negative-offset-ext-sm {
    margin: 0 -15px;
  }
}

@media (min-width: 771px) {
  .img-offset-left {
    margin-left: -70px;
    width: calc(100% + 70px);
    max-width: calc(100% + 70px);
  }
}

.youtube-block {
  position: relative;
  padding-bottom: 56.25%;
  margin: 0 -23px;
}

@media (min-width: 771px) {
  .youtube-block {
    margin: 0;
    height: 219px;
    width: calc(100% + 40px);
    margin-left: -40px;
  }
}

@media (min-width: 992px) {
  .youtube-block {
    height: 304px;
    width: calc(100% + 80px);
    margin-left: -80px;
  }
}

@media (min-width: 1200px) {
  .youtube-block {
    height: 354px;
    width: calc(100% + 80px);
    margin-left: -80px;
  }
}

.youtube-block iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Aspect ratio */
[class*='aspect-ratio-'] {
  display: block;
  position: relative;
}

[class*='aspect-ratio-'] > img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.aspect-ratio-wide {
  padding-top: 56.25%;
}

.aspect-ratio-square {
  padding-top: 100%;
}

/* Margin helpers */
.margin-0 {
  margin: 0 !important;
}

@media (min-width: 576px) {
  .margin-xs-0 {
    margin: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-sm-0 {
    margin: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-md-0 {
    margin: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-lg-0 {
    margin: 0 !important;
  }
}

@media (min-width: 1440px) {
  .margin-xl-0 {
    margin: 0 !important;
  }
}

.margin-bottom-01 {
  margin-bottom: 0.1rem;
}

.margin-bottom-02 {
  margin-bottom: 0.2rem;
}

.margin-bottom-03 {
  margin-bottom: 0.3rem;
}

.margin-bottom-04 {
  margin-bottom: 0.4rem;
}

.margin-bottom-05 {
  margin-bottom: 0.5rem;
}

.margin-bottom-06 {
  margin-bottom: 0.6rem;
}

.margin-bottom-07 {
  margin-bottom: 0.7rem;
}

.margin-bottom-08 {
  margin-bottom: 0.8rem;
}

.margin-bottom-09 {
  margin-bottom: 0.9rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-bottom-1-5 {
  margin-bottom: 1.5rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-bottom-2-5 {
  margin-bottom: 2.5rem;
}

.margin-bottom-3 {
  margin-bottom: 3rem;
}

.margin-bottom-3-5 {
  margin-bottom: 3.5rem;
}

.margin-bottom-4 {
  margin-bottom: 4rem;
}

.margin-bottom-5 {
  margin-bottom: 5rem;
}

.margin-bottom-6 {
  margin-bottom: 6rem;
}

.margin-bottom-7 {
  margin-bottom: 7rem;
}

.margin-bottom-8 {
  margin-bottom: 8rem;
}

.margin-bottom-9 {
  margin-bottom: 9rem;
}

.margin-bottom-10 {
  margin-bottom: 10rem;
}

@media (min-width: 576px) {
  .margin-bottom-xs-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-xs-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-xs-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-xs-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-xs-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-xs-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-bottom-sm-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-sm-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-sm-2-5 {
    margin-bottom: 2.5rem;
  }
  .margin-bottom-sm-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-sm-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-sm-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-sm-6 {
    margin-bottom: 6rem;
  }
  .margin-bottom-sm-7 {
    margin-bottom: 7rem;
  }
  .margin-bottom-sm-8 {
    margin-bottom: 8rem;
  }
}

@media (min-width: 992px) {
  .margin-bottom-md-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-md-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-md-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-md-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-md-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-md-6 {
    margin-bottom: 6rem;
  }
  .margin-bottom-md-7 {
    margin-bottom: 7rem;
  }
  .margin-bottom-md-8 {
    margin-bottom: 8rem;
  }
  .margin-bottom-md-10 {
    margin-bottom: 10rem;
  }
  .margin-bottom-md-12 {
    margin-bottom: 12rem;
  }
}

@media (min-width: 1200px) {
  .margin-bottom-lg-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-lg-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-lg-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-lg-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-lg-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-lg-6 {
    margin-bottom: 6rem;
  }
  .margin-bottom-lg-8 {
    margin-bottom: 8rem;
  }
}

@media (min-width: 1440px) {
  .margin-bottom-xl-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-xl-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-xl-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-xl-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-xl-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-xl-6 {
    margin-bottom: 6rem;
  }
}

.margin-top-01 {
  margin-top: 0.1rem;
}

.margin-top-02 {
  margin-top: 0.2rem;
}

.margin-top-03 {
  margin-top: 0.3rem;
}

.margin-top-04 {
  margin-top: 0.4rem;
}

.margin-top-05 {
  margin-top: 0.5rem;
}

.margin-top-06 {
  margin-top: 0.6rem;
}

.margin-top-07 {
  margin-top: 0.7rem;
}

.margin-top-08 {
  margin-top: 0.8rem;
}

.margin-top-09 {
  margin-top: 0.9rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-1-5 {
  margin-top: 1.5rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-2-5 {
  margin-top: 2.5rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-top-3-5 {
  margin-top: 3.5rem;
}

.margin-top-4 {
  margin-top: 4rem;
}

.margin-top-5 {
  margin-top: 5rem;
}

.margin-top-6 {
  margin-top: 6rem;
}

.margin-top-7 {
  margin-top: 7rem;
}

.margin-top-8 {
  margin-top: 8rem;
}

.margin-top-9 {
  margin-top: 9rem;
}

.margin-top-10 {
  margin-top: 10rem;
}

@media (min-width: 576px) {
  .margin-top-xs-1 {
    margin-top: 1rem;
  }
  .margin-top-xs-2 {
    margin-top: 2rem;
  }
  .margin-top-xs-3 {
    margin-top: 3rem;
  }
  .margin-top-xs-4 {
    margin-top: 4rem;
  }
  .margin-top-xs-5 {
    margin-top: 5rem;
  }
  .margin-top-xs-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-top-sm-1 {
    margin-top: 1rem;
  }
  .margin-top-sm-2 {
    margin-top: 2rem;
  }
  .margin-top-sm-2-5 {
    margin-top: 2.5rem;
  }
  .margin-top-sm-3 {
    margin-top: 3rem;
  }
  .margin-top-sm-4 {
    margin-top: 4rem;
  }
  .margin-top-sm-5 {
    margin-top: 5rem;
  }
  .margin-top-sm-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-top-md-1 {
    margin-top: 1rem;
  }
  .margin-top-md-2 {
    margin-top: 2rem;
  }
  .margin-top-md-2-5 {
    margin-top: 2.5rem;
  }
  .margin-top-md-3 {
    margin-top: 3rem;
  }
  .margin-top-md-4 {
    margin-top: 4rem;
  }
  .margin-top-md-5 {
    margin-top: 5rem;
  }
  .margin-top-md-6 {
    margin-top: 6rem;
  }
  .margin-top-md-8 {
    margin-top: 8rem;
  }
}

@media (min-width: 1200px) {
  .margin-top-lg-1 {
    margin-top: 1rem;
  }
  .margin-top-lg-2 {
    margin-top: 2rem;
  }
  .margin-top-lg-3 {
    margin-top: 3rem;
  }
  .margin-top-lg-4 {
    margin-top: 4rem;
  }
  .margin-top-lg-5 {
    margin-top: 5rem;
  }
  .margin-top-lg-6 {
    margin-top: 6rem;
  }
  .margin-top-lg-8 {
    margin-top: 8rem;
  }
}

@media (min-width: 1440px) {
  .margin-top-xl-1 {
    margin-top: 1rem;
  }
  .margin-top-xl-2 {
    margin-top: 2rem;
  }
  .margin-top-xl-3 {
    margin-top: 3rem;
  }
  .margin-top-xl-4 {
    margin-top: 4rem;
  }
  .margin-top-xl-5 {
    margin-top: 5rem;
  }
  .margin-top-xl-6 {
    margin-top: 6rem;
  }
}

.margin-left-01 {
  margin-left: 0.1rem;
}

.margin-left-02 {
  margin-left: 0.2rem;
}

.margin-left-03 {
  margin-left: 0.3rem;
}

.margin-left-04 {
  margin-left: 0.4rem;
}

.margin-left-05 {
  margin-left: 0.5rem;
}

.margin-left-08 {
  margin-left: 0.8rem;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-left-1-5 {
  margin-left: 1.5rem;
}

.margin-left-2 {
  margin-left: 2rem;
}

.margin-left-3 {
  margin-left: 3rem;
}

.margin-left-4 {
  margin-left: 4rem;
}

.margin-left-5 {
  margin-left: 5rem;
}

.margin-left-6 {
  margin-left: 6rem;
}

.margin-left-7 {
  margin-left: 7rem;
}

.margin-left-8 {
  margin-left: 8rem;
}

.margin-left-9 {
  margin-left: 9rem;
}

.margin-left-10 {
  margin-left: 10rem;
}

@media (min-width: 576px) {
  .margin-left-xs-1 {
    margin-left: 1rem;
  }
  .margin-left-xs-2 {
    margin-left: 2rem;
  }
  .margin-left-xs-3 {
    margin-left: 3rem;
  }
  .margin-left-xs-4 {
    margin-left: 4rem;
  }
  .margin-left-xs-5 {
    margin-left: 5rem;
  }
  .margin-left-xs-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-left-sm-1 {
    margin-left: 1rem;
  }
  .margin-left-sm-2 {
    margin-left: 2rem;
  }
  .margin-left-sm-3 {
    margin-left: 3rem;
  }
  .margin-left-sm-4 {
    margin-left: 4rem;
  }
  .margin-left-sm-5 {
    margin-left: 5rem;
  }
  .margin-left-sm-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-left-md-1 {
    margin-left: 1rem;
  }
  .margin-left-md-2 {
    margin-left: 2rem;
  }
  .margin-left-md-3 {
    margin-left: 3rem;
  }
  .margin-left-md-4 {
    margin-left: 4rem;
  }
  .margin-left-md-5 {
    margin-left: 5rem;
  }
  .margin-left-md-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-left-lg-1 {
    margin-left: 1rem;
  }
  .margin-left-lg-2 {
    margin-left: 2rem;
  }
  .margin-left-lg-3 {
    margin-left: 3rem;
  }
  .margin-left-lg-4 {
    margin-left: 4rem;
  }
  .margin-left-lg-5 {
    margin-left: 5rem;
  }
  .margin-left-lg-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-left-xl-1 {
    margin-left: 1rem;
  }
  .margin-left-xl-2 {
    margin-left: 2rem;
  }
  .margin-left-xl-3 {
    margin-left: 3rem;
  }
  .margin-left-xl-4 {
    margin-left: 4rem;
  }
  .margin-left-xl-5 {
    margin-left: 5rem;
  }
  .margin-left-xl-6 {
    margin-left: 6rem;
  }
}

.margin-right-01 {
  margin-right: 0.1rem;
}

.margin-right-02 {
  margin-right: 0.2rem;
}

.margin-right-03 {
  margin-right: 0.3rem;
}

.margin-right-04 {
  margin-right: 0.4rem;
}

.margin-right-05 {
  margin-right: 0.5rem;
}

.margin-right-08 {
  margin-right: 0.8rem;
}

.margin-right-1 {
  margin-right: 1rem;
}

.margin-right-1-5 {
  margin-right: 1.5rem;
}

.margin-right-2 {
  margin-right: 2rem;
}

.margin-right-3 {
  margin-right: 3rem;
}

.margin-right-4 {
  margin-right: 4rem;
}

.margin-right-5 {
  margin-right: 5rem;
}

.margin-right-6 {
  margin-right: 6rem;
}

.margin-right-7 {
  margin-right: 7rem;
}

.margin-right-8 {
  margin-right: 8rem;
}

.margin-right-9 {
  margin-right: 9rem;
}

.margin-right-10 {
  margin-right: 10rem;
}

@media (min-width: 576px) {
  .margin-right-xs-1 {
    margin-right: 1rem;
  }
  .margin-right-xs-2 {
    margin-right: 2rem;
  }
  .margin-right-xs-3 {
    margin-right: 3rem;
  }
  .margin-right-xs-4 {
    margin-right: 4rem;
  }
  .margin-right-xs-5 {
    margin-right: 5rem;
  }
  .margin-right-xs-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-right-sm-1 {
    margin-right: 1rem;
  }
  .margin-right-sm-2 {
    margin-right: 2rem;
  }
  .margin-right-sm-3 {
    margin-right: 3rem;
  }
  .margin-right-sm-4 {
    margin-right: 4rem;
  }
  .margin-right-sm-5 {
    margin-right: 5rem;
  }
  .margin-right-sm-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-right-md-1 {
    margin-right: 1rem;
  }
  .margin-right-md-2 {
    margin-right: 2rem;
  }
  .margin-right-md-3 {
    margin-right: 3rem;
  }
  .margin-right-md-4 {
    margin-right: 4rem;
  }
  .margin-right-md-5 {
    margin-right: 5rem;
  }
  .margin-right-md-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-right-lg-1 {
    margin-right: 1rem;
  }
  .margin-right-lg-2 {
    margin-right: 2rem;
  }
  .margin-right-lg-3 {
    margin-right: 3rem;
  }
  .margin-right-lg-4 {
    margin-right: 4rem;
  }
  .margin-right-lg-5 {
    margin-right: 5rem;
  }
  .margin-right-lg-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-right-xl-1 {
    margin-right: 1rem;
  }
  .margin-right-xl-2 {
    margin-right: 2rem;
  }
  .margin-right-xl-3 {
    margin-right: 3rem;
  }
  .margin-right-xl-4 {
    margin-right: 4rem;
  }
  .margin-right-xl-5 {
    margin-right: 5rem;
  }
  .margin-right-xl-6 {
    margin-right: 6rem;
  }
}

.margin-center {
  margin: 0 auto;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-right-auto {
  margin-right: auto;
}

@media (min-width: 576px) {
  .margin-xs-center {
    margin: 0 auto;
  }
  .margin-xs-left-auto {
    margin-left: auto;
  }
  .margin-xs-right-auto {
    margin-right: auto;
  }
}

@media (min-width: 771px) {
  .margin-sm-center {
    margin: 0 auto;
  }
  .margin-sm-left-auto {
    margin-left: auto;
  }
  .margin-sm-right-auto {
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .margin-md-center {
    margin: 0 auto;
  }
  .margin-md-left-auto {
    margin-left: auto;
  }
  .margin-md-right-auto {
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .margin-lg-center {
    margin: 0 auto;
  }
  .margin-lg-left-auto {
    margin-left: auto;
  }
  .margin-lg-right-auto {
    margin-right: auto;
  }
}

.margin-top-0 {
  margin-top: 0 !important;
}

@media (min-width: 576px) {
  .margin-top-xs-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-top-sm-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-top-md-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-top-lg-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-top-lg-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-top-md-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-top-sm-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-top-xs-0 {
    margin-top: 0 !important;
  }
}

.margin-right-0 {
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .margin-right-xs-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-right-sm-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-right-md-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-right-lg-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-right-lg-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-right-md-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-right-sm-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-right-xs-0 {
    margin-right: 0 !important;
  }
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .margin-bottom-xs-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-bottom-sm-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-bottom-md-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-bottom-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-bottom-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-bottom-md-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-bottom-sm-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-bottom-xs-0 {
    margin-bottom: 0 !important;
  }
}

.margin-left-0 {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .margin-left-xs-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-left-sm-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-left-md-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-left-lg-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-left-lg-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-left-md-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-left-sm-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-left-xs-0 {
    margin-left: 0 !important;
  }
}

/* Padding block */
.padding-0 {
  padding: 0 !important;
}

@media (min-width: 576px) {
  .padding-xs-0 {
    padding: 0 !important;
  }
}

@media (min-width: 771px) {
  .padding-sm-0 {
    padding: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-md-0 {
    padding: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-lg-0 {
    padding: 0 !important;
  }
}

@media (min-width: 1440px) {
  .padding-xl-0 {
    padding: 0 !important;
  }
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-top-xs-0 {
  padding-top: 0 !important;
}

@media (min-width: 771px) {
  .padding-top-sm-0 {
    padding-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-top-md-0 {
    padding-top: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-top-lg-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-top-lg-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-top-md-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-top-sm-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-top-xs-0 {
    padding-top: 0 !important;
  }
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-right-xs-0 {
  padding-right: 0 !important;
}

@media (min-width: 771px) {
  .padding-right-sm-0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-right-md-0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-right-lg-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-right-lg-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-right-md-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-right-sm-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-right-xs-0 {
    padding-right: 0 !important;
  }
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-xs-0 {
  padding-bottom: 0 !important;
}

@media (min-width: 771px) {
  .padding-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-bottom-md-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-bottom-md-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-bottom-xs-0 {
    padding-bottom: 0 !important;
  }
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-left-xs-0 {
  padding-left: 0 !important;
}

@media (min-width: 771px) {
  .padding-left-sm-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-left-md-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-left-lg-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-left-lg-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-left-md-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-left-sm-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-left-xs-0 {
    padding-left: 0 !important;
  }
}

/* Position helpers */
.pos-r {
  position: relative;
}

.pos-f {
  position: fixed;
}

.pos-s {
  position: static;
}

.pos-sticky {
  position: sticky;
}

.pos-a {
  position: absolute;
}

.pos-auto {
  position: inherit;
}

@media (min-width: 771px) {
  .pos-sticky-job-detail {
    top: 100px;
  }
}

/* Size helpers */
.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.h-vh-100 {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50%;
}

.h-25 {
  height: 25%;
}

/* Text helpers */
.txt-r {
  text-align: right;
}

.txt-c {
  text-align: center;
}

.txt-l {
  text-align: left;
}

@media (min-width: 576px) {
  .txt-xs-c {
    text-align: center;
  }
  .txt-xs-l {
    text-align: left;
  }
  .txt-xs-r {
    text-align: right;
  }
}

@media (min-width: 771px) {
  .txt-sm-l {
    text-align: left;
  }
  .txt-sm-r {
    text-align: right;
  }
  .txt-sm-c {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .txt-md-l {
    text-align: left;
  }
  .txt-md-r {
    text-align: right;
  }
  .txt-md-c {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .txt-lg-r {
    text-align: right;
  }
  .txt-lg-l {
    text-align: left;
  }
  .txt-lg-c {
    text-align: center;
  }
}

.txt-upper {
  text-transform: uppercase;
}

.txt-lower {
  text-transform: lowercase;
}

.txt-italic {
  font-style: italic;
}

.txt-300 {
  font-weight: 300;
}

.txt-400 {
  font-weight: 400;
}

.txt-700 {
  font-weight: 700;
}

.txt-line {
  text-decoration: line-through;
}

.txt-underline {
  text-decoration: underline;
}

/* Z-index helpers */
.z-index--1 {
  z-index: -1;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-index-8 {
  z-index: 8;
}

.z-index-9 {
  z-index: 9;
}

.z-index-10 {
  z-index: 10;
}

.z-index-100 {
  z-index: 100;
}

.z-index-1000 {
  z-index: 1000;
}

.z-index-10000 {
  z-index: 10000;
}

.z-index-100000 {
  z-index: 100000;
}

/* Opacity helpers */
.opacity-0 {
  opacity: 0;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.opacity-1 {
  opacity: 1;
}

/* Dots helpers */
.dots-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dots-text--2 {
  -webkit-line-clamp: 2;
}

.dots-text--3 {
  -webkit-line-clamp: 3;
}

.dots-text--4 {
  -webkit-line-clamp: 4;
}

.dots {
  overflow: hidden;
}

/* IE fallback */
.no-object-fit .img-flex-cover img {
  height: auto;
  width: auto;
  vertical-align: middle;
  margin: 0 auto;
}

.no-object-fit .img-flex-contain {
  display: block;
  height: auto;
}

.no-object-fit .img-flex-contain img {
  height: 100%;
  width: auto;
  vertical-align: middle;
  margin: 0 auto;
}

/* Base settings */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  transition: color 0.15s, background 0.15s, border 0.15s, opacity 0.15s;
}

*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

figure {
  margin: 0;
}

html {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  font-size: 100%;
}

@media (min-width: 2000px) {
  html {
    font-size: 150%;
  }
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 310px;
  margin: 0;
  color: #000;
  background-color: #ffffff;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

em,
i,
.italic {
  font-style: italic;
}

strong,
.strong {
  font-weight: 700;
}

small,
.small {
  font-size: 12px;
  font-size: 0.75rem;
}

h1,
.alfa,
h2,
.beta,
h3,
.gamma,
h4,
.delta,
h5,
.epsilon,
h6,
.zeta {
  margin: 0;
  margin-bottom: 1.5rem;
  color: #000000;
  font-family: "Blacker-Pro-ExBd", Arial, sans-serif;
  font-weight: 800;
  line-height: 1.2;
  line-height: calc(2px + 2ex + 2px);
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  transition: font-size 0.3s cubic-bezier(0.16, 0.08, 0.355, 1);
}

h1 a,
.alfa a,
h2 a,
.beta a,
h3 a,
.gamma a,
h4 a,
.delta a,
h5 a,
.epsilon a,
h6 a,
.zeta a {
  font-family: "Blacker-Pro-ExBd", Arial, sans-serif;
  color: inherit;
}

h1,
.alfa {
  font-size: 40px;
  font-size: 2.5rem;
}

@media (min-width: 576px) {
  h1,
  .alfa {
    font-size: 50px;
    font-size: 3.125rem;
  }
}

@media (min-width: 771px) {
  h1,
  .alfa {
    font-size: 60px;
    font-size: 3.75rem;
  }
}

@media (min-width: 992px) {
  h1,
  .alfa {
    font-size: 72px;
    font-size: 4.5rem;
  }
}

.alfa-big {
  font-size: 120px;
  font-size: 7.5rem;
}

h2,
.beta {
  font-size: 36px;
  font-size: 2.25rem;
}

@media (min-width: 576px) {
  h2,
  .beta {
    font-size: 42px;
    font-size: 2.625rem;
  }
}

@media (min-width: 771px) {
  h2,
  .beta {
    font-size: 46px;
    font-size: 2.875rem;
  }
}

@media (min-width: 992px) {
  h2,
  .beta {
    font-size: 52px;
    font-size: 3.25rem;
  }
}

h3,
.gamma {
  font-size: 30px;
  font-size: 1.875rem;
}

@media (min-width: 576px) {
  h3,
  .gamma {
    font-size: 36px;
    font-size: 2.25rem;
  }
}

@media (min-width: 771px) {
  h3,
  .gamma {
    font-size: 38px;
    font-size: 2.375rem;
  }
}

@media (min-width: 992px) {
  h3,
  .gamma {
    font-size: 42px;
    font-size: 2.625rem;
  }
}

h4,
.delta {
  font-size: 24px;
  font-size: 1.5rem;
}

@media (min-width: 576px) {
  h4,
  .delta {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

@media (min-width: 771px) {
  h4,
  .delta {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

h5,
.epsilon {
  font-size: 20px;
  font-size: 1.25rem;
}

@media (min-width: 576px) {
  h5,
  .epsilon {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

@media (min-width: 771px) {
  h5,
  .epsilon {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

h6,
.zeta {
  font-size: 20px;
  font-size: 1.25rem;
}

@media (min-width: 576px) {
  h6,
  .zeta {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media (min-width: 771px) {
  h6,
  .zeta {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.h--font-light h1,
.h--font-light .alfa,
.h--font-light h2,
.h--font-light .beta,
.h--font-light h3,
.h--font-light .gamma,
.h--font-light h4,
.h--font-light .delta,
.h--font-light h5,
.h--font-light .epsilon,
.h--font-light h6,
.h--font-light .zeta {
  font-weight: 300;
}

.h--font-regular h1,
.h--font-regular .alfa,
.h--font-regular h2,
.h--font-regular .beta,
.h--font-regular h3,
.h--font-regular .gamma,
.h--font-regular h4,
.h--font-regular .delta,
.h--font-regular h5,
.h--font-regular .epsilon,
.h--font-regular h6,
.h--font-regular .zeta {
  font-weight: 400;
}

.h--font-semibold h1,
.h--font-semibold .alfa,
.h--font-semibold h2,
.h--font-semibold .beta,
.h--font-semibold h3,
.h--font-semibold .gamma,
.h--font-semibold h4,
.h--font-semibold .delta,
.h--font-semibold h5,
.h--font-semibold .epsilon,
.h--font-semibold h6,
.h--font-semibold .zeta {
  font-weight: 600;
}

.h--font-bold h1,
.h--font-bold .alfa,
.h--font-bold h2,
.h--font-bold .beta,
.h--font-bold h3,
.h--font-bold .gamma,
.h--font-bold h4,
.h--font-bold .delta,
.h--font-bold h5,
.h--font-bold .epsilon,
.h--font-bold h6,
.h--font-bold .zeta {
  font-weight: 700;
}

.h--font-black h1,
.h--font-black .alfa,
.h--font-black h2,
.h--font-black .beta,
.h--font-black h3,
.h--font-black .gamma,
.h--font-black h4,
.h--font-black .delta,
.h--font-black h5,
.h--font-black .epsilon,
.h--font-black h6,
.h--font-black .zeta {
  font-weight: 900;
}

.h--font-primary-regular h1,
.h--font-primary-regular .alfa,
.h--font-primary-regular h2,
.h--font-primary-regular .beta,
.h--font-primary-regular h3,
.h--font-primary-regular .gamma,
.h--font-primary-regular h4,
.h--font-primary-regular .delta,
.h--font-primary-regular h5,
.h--font-primary-regular .epsilon,
.h--font-primary-regular h6,
.h--font-primary-regular .zeta {
  font-family: "Gilroy-Regular", Arial, sans-serif;
}

.h--font-secondary h1,
.h--font-secondary .alfa,
.h--font-secondary h2,
.h--font-secondary .beta,
.h--font-secondary h3,
.h--font-secondary .gamma,
.h--font-secondary h4,
.h--font-secondary .delta,
.h--font-secondary h5,
.h--font-secondary .epsilon,
.h--font-secondary h6,
.h--font-secondary .zeta {
  font-family: "Blacker-Pro-ExBd", Arial, sans-serif;
}

.h--text-upper h1,
.h--text-upper .alfa,
.h--text-upper h2,
.h--text-upper .beta,
.h--text-upper h3,
.h--text-upper .gamma,
.h--text-upper h4,
.h--text-upper .delta,
.h--text-upper h5,
.h--text-upper .epsilon,
.h--text-upper h6,
.h--text-upper .zeta {
  text-transform: uppercase;
}

.h--line-08 h1,
.h--line-08 .alfa,
.h--line-08 h2,
.h--line-08 .beta,
.h--line-08 h3,
.h--line-08 .gamma,
.h--line-08 h4,
.h--line-08 .delta,
.h--line-08 h5,
.h--line-08 .epsilon,
.h--line-08 h6,
.h--line-08 .zeta {
  line-height: 0.8;
}

.h--line-1 h1,
.h--line-1 .alfa,
.h--line-1 h2,
.h--line-1 .beta,
.h--line-1 h3,
.h--line-1 .gamma,
.h--line-1 h4,
.h--line-1 .delta,
.h--line-1 h5,
.h--line-1 .epsilon,
.h--line-1 h6,
.h--line-1 .zeta {
  line-height: 1;
}

.h--line-1-2 h1,
.h--line-1-2 .alfa,
.h--line-1-2 h2,
.h--line-1-2 .beta,
.h--line-1-2 h3,
.h--line-1-2 .gamma,
.h--line-1-2 h4,
.h--line-1-2 .delta,
.h--line-1-2 h5,
.h--line-1-2 .epsilon,
.h--line-1-2 h6,
.h--line-1-2 .zeta {
  line-height: 1.2;
}

.h--line-1-4 h1,
.h--line-1-4 .alfa,
.h--line-1-4 h2,
.h--line-1-4 .beta,
.h--line-1-4 h3,
.h--line-1-4 .gamma,
.h--line-1-4 h4,
.h--line-1-4 .delta,
.h--line-1-4 h5,
.h--line-1-4 .epsilon,
.h--line-1-4 h6,
.h--line-1-4 .zeta {
  line-height: 1.4;
}

.h--line-1-5 h1,
.h--line-1-5 .alfa,
.h--line-1-5 h2,
.h--line-1-5 .beta,
.h--line-1-5 h3,
.h--line-1-5 .gamma,
.h--line-1-5 h4,
.h--line-1-5 .delta,
.h--line-1-5 h5,
.h--line-1-5 .epsilon,
.h--line-1-5 h6,
.h--line-1-5 .zeta {
  line-height: 1.5;
}

.h--line-1-6 h1,
.h--line-1-6 .alfa,
.h--line-1-6 h2,
.h--line-1-6 .beta,
.h--line-1-6 h3,
.h--line-1-6 .gamma,
.h--line-1-6 h4,
.h--line-1-6 .delta,
.h--line-1-6 h5,
.h--line-1-6 .epsilon,
.h--line-1-6 h6,
.h--line-1-6 .zeta {
  line-height: 1.6;
}

.h--line-1-8 h1,
.h--line-1-8 .alfa,
.h--line-1-8 h2,
.h--line-1-8 .beta,
.h--line-1-8 h3,
.h--line-1-8 .gamma,
.h--line-1-8 h4,
.h--line-1-8 .delta,
.h--line-1-8 h5,
.h--line-1-8 .epsilon,
.h--line-1-8 h6,
.h--line-1-8 .zeta {
  line-height: 1.8;
}

.h--line-2 h1,
.h--line-2 .alfa,
.h--line-2 h2,
.h--line-2 .beta,
.h--line-2 h3,
.h--line-2 .gamma,
.h--line-2 h4,
.h--line-2 .delta,
.h--line-2 h5,
.h--line-2 .epsilon,
.h--line-2 h6,
.h--line-2 .zeta {
  line-height: 2;
}

.h--line-2-2 h1,
.h--line-2-2 .alfa,
.h--line-2-2 h2,
.h--line-2-2 .beta,
.h--line-2-2 h3,
.h--line-2-2 .gamma,
.h--line-2-2 h4,
.h--line-2-2 .delta,
.h--line-2-2 h5,
.h--line-2-2 .epsilon,
.h--line-2-2 h6,
.h--line-2-2 .zeta {
  line-height: 2.2;
}

.h--line-2-5 h1,
.h--line-2-5 .alfa,
.h--line-2-5 h2,
.h--line-2-5 .beta,
.h--line-2-5 h3,
.h--line-2-5 .gamma,
.h--line-2-5 h4,
.h--line-2-5 .delta,
.h--line-2-5 h5,
.h--line-2-5 .epsilon,
.h--line-2-5 h6,
.h--line-2-5 .zeta {
  line-height: 2.5;
}

@media (min-width: 576px) {
  .h--line-xs-1 h1,
  .h--line-xs-1 .alfa,
  .h--line-xs-1 h2,
  .h--line-xs-1 .beta,
  .h--line-xs-1 h3,
  .h--line-xs-1 .gamma,
  .h--line-xs-1 h4,
  .h--line-xs-1 .delta,
  .h--line-xs-1 h5,
  .h--line-xs-1 .epsilon,
  .h--line-xs-1 h6,
  .h--line-xs-1 .zeta {
    line-height: 1;
  }
  .h--line-xs-1-2 h1,
  .h--line-xs-1-2 .alfa,
  .h--line-xs-1-2 h2,
  .h--line-xs-1-2 .beta,
  .h--line-xs-1-2 h3,
  .h--line-xs-1-2 .gamma,
  .h--line-xs-1-2 h4,
  .h--line-xs-1-2 .delta,
  .h--line-xs-1-2 h5,
  .h--line-xs-1-2 .epsilon,
  .h--line-xs-1-2 h6,
  .h--line-xs-1-2 .zeta {
    line-height: 1.2;
  }
  .h--line-xs-1-4 h1,
  .h--line-xs-1-4 .alfa,
  .h--line-xs-1-4 h2,
  .h--line-xs-1-4 .beta,
  .h--line-xs-1-4 h3,
  .h--line-xs-1-4 .gamma,
  .h--line-xs-1-4 h4,
  .h--line-xs-1-4 .delta,
  .h--line-xs-1-4 h5,
  .h--line-xs-1-4 .epsilon,
  .h--line-xs-1-4 h6,
  .h--line-xs-1-4 .zeta {
    line-height: 1.4;
  }
  .h--line-xs-1-6 h1,
  .h--line-xs-1-6 .alfa,
  .h--line-xs-1-6 h2,
  .h--line-xs-1-6 .beta,
  .h--line-xs-1-6 h3,
  .h--line-xs-1-6 .gamma,
  .h--line-xs-1-6 h4,
  .h--line-xs-1-6 .delta,
  .h--line-xs-1-6 h5,
  .h--line-xs-1-6 .epsilon,
  .h--line-xs-1-6 h6,
  .h--line-xs-1-6 .zeta {
    line-height: 1.6;
  }
}

@media (min-width: 771px) {
  .h--line-sm-1 h1,
  .h--line-sm-1 .alfa,
  .h--line-sm-1 h2,
  .h--line-sm-1 .beta,
  .h--line-sm-1 h3,
  .h--line-sm-1 .gamma,
  .h--line-sm-1 h4,
  .h--line-sm-1 .delta,
  .h--line-sm-1 h5,
  .h--line-sm-1 .epsilon,
  .h--line-sm-1 h6,
  .h--line-sm-1 .zeta {
    line-height: 1;
  }
  .h--line-sm-1-2 h1,
  .h--line-sm-1-2 .alfa,
  .h--line-sm-1-2 h2,
  .h--line-sm-1-2 .beta,
  .h--line-sm-1-2 h3,
  .h--line-sm-1-2 .gamma,
  .h--line-sm-1-2 h4,
  .h--line-sm-1-2 .delta,
  .h--line-sm-1-2 h5,
  .h--line-sm-1-2 .epsilon,
  .h--line-sm-1-2 h6,
  .h--line-sm-1-2 .zeta {
    line-height: 1.2;
  }
  .h--line-sm-1-4 h1,
  .h--line-sm-1-4 .alfa,
  .h--line-sm-1-4 h2,
  .h--line-sm-1-4 .beta,
  .h--line-sm-1-4 h3,
  .h--line-sm-1-4 .gamma,
  .h--line-sm-1-4 h4,
  .h--line-sm-1-4 .delta,
  .h--line-sm-1-4 h5,
  .h--line-sm-1-4 .epsilon,
  .h--line-sm-1-4 h6,
  .h--line-sm-1-4 .zeta {
    line-height: 1.4;
  }
  .h--line-sm-1-6 h1,
  .h--line-sm-1-6 .alfa,
  .h--line-sm-1-6 h2,
  .h--line-sm-1-6 .beta,
  .h--line-sm-1-6 h3,
  .h--line-sm-1-6 .gamma,
  .h--line-sm-1-6 h4,
  .h--line-sm-1-6 .delta,
  .h--line-sm-1-6 h5,
  .h--line-sm-1-6 .epsilon,
  .h--line-sm-1-6 h6,
  .h--line-sm-1-6 .zeta {
    line-height: 1.6;
  }
}

@media (min-width: 992px) {
  .h--line-md-1 h1,
  .h--line-md-1 .alfa,
  .h--line-md-1 h2,
  .h--line-md-1 .beta,
  .h--line-md-1 h3,
  .h--line-md-1 .gamma,
  .h--line-md-1 h4,
  .h--line-md-1 .delta,
  .h--line-md-1 h5,
  .h--line-md-1 .epsilon,
  .h--line-md-1 h6,
  .h--line-md-1 .zeta {
    line-height: 1;
  }
  .h--line-md-1-2 h1,
  .h--line-md-1-2 .alfa,
  .h--line-md-1-2 h2,
  .h--line-md-1-2 .beta,
  .h--line-md-1-2 h3,
  .h--line-md-1-2 .gamma,
  .h--line-md-1-2 h4,
  .h--line-md-1-2 .delta,
  .h--line-md-1-2 h5,
  .h--line-md-1-2 .epsilon,
  .h--line-md-1-2 h6,
  .h--line-md-1-2 .zeta {
    line-height: 1.2;
  }
  .h--line-md-1-4 h1,
  .h--line-md-1-4 .alfa,
  .h--line-md-1-4 h2,
  .h--line-md-1-4 .beta,
  .h--line-md-1-4 h3,
  .h--line-md-1-4 .gamma,
  .h--line-md-1-4 h4,
  .h--line-md-1-4 .delta,
  .h--line-md-1-4 h5,
  .h--line-md-1-4 .epsilon,
  .h--line-md-1-4 h6,
  .h--line-md-1-4 .zeta {
    line-height: 1.4;
  }
  .h--line-md-1-6 h1,
  .h--line-md-1-6 .alfa,
  .h--line-md-1-6 h2,
  .h--line-md-1-6 .beta,
  .h--line-md-1-6 h3,
  .h--line-md-1-6 .gamma,
  .h--line-md-1-6 h4,
  .h--line-md-1-6 .delta,
  .h--line-md-1-6 h5,
  .h--line-md-1-6 .epsilon,
  .h--line-md-1-6 h6,
  .h--line-md-1-6 .zeta {
    line-height: 1.6;
  }
}

.h--margin-0 .alfa,
.h--margin-0 .beta,
.h--margin-0 .gamma,
.h--margin-0 .delta,
.h--margin-0 .epsilon,
.h--margin-0 .zeta,
.h--margin-0 h1,
.h--margin-0 h2,
.h--margin-0 h3,
.h--margin-0 h4,
.h--margin-0 h5,
.h--margin-0 h6 {
  margin-bottom: 0;
}

.h--margin-01 .alfa,
.h--margin-01 .beta,
.h--margin-01 .gamma,
.h--margin-01 .delta,
.h--margin-01 .epsilon,
.h--margin-01 .zeta,
.h--margin-01 h1,
.h--margin-01 h2,
.h--margin-01 h3,
.h--margin-01 h4,
.h--margin-01 h5,
.h--margin-01 h6 {
  margin-bottom: 0.1rem;
}

.h--margin-02 .alfa,
.h--margin-02 .beta,
.h--margin-02 .gamma,
.h--margin-02 .delta,
.h--margin-02 .epsilon,
.h--margin-02 .zeta,
.h--margin-02 h1,
.h--margin-02 h2,
.h--margin-02 h3,
.h--margin-02 h4,
.h--margin-02 h5,
.h--margin-02 h6 {
  margin-bottom: 0.2rem;
}

.h--margin-03 .alfa,
.h--margin-03 .beta,
.h--margin-03 .gamma,
.h--margin-03 .delta,
.h--margin-03 .epsilon,
.h--margin-03 .zeta,
.h--margin-03 h1,
.h--margin-03 h2,
.h--margin-03 h3,
.h--margin-03 h4,
.h--margin-03 h5,
.h--margin-03 h6 {
  margin-bottom: 0.3rem;
}

.h--margin-04 .alfa,
.h--margin-04 .beta,
.h--margin-04 .gamma,
.h--margin-04 .delta,
.h--margin-04 .epsilon,
.h--margin-04 .zeta,
.h--margin-04 h1,
.h--margin-04 h2,
.h--margin-04 h3,
.h--margin-04 h4,
.h--margin-04 h5,
.h--margin-04 h6 {
  margin-bottom: 0.4rem;
}

.h--margin-05 .alfa,
.h--margin-05 .beta,
.h--margin-05 .gamma,
.h--margin-05 .delta,
.h--margin-05 .epsilon,
.h--margin-05 .zeta,
.h--margin-05 h1,
.h--margin-05 h2,
.h--margin-05 h3,
.h--margin-05 h4,
.h--margin-05 h5,
.h--margin-05 h6 {
  margin-bottom: 0.5rem;
}

.h--margin-06 .alfa,
.h--margin-06 .beta,
.h--margin-06 .gamma,
.h--margin-06 .delta,
.h--margin-06 .epsilon,
.h--margin-06 .zeta,
.h--margin-06 h1,
.h--margin-06 h2,
.h--margin-06 h3,
.h--margin-06 h4,
.h--margin-06 h5,
.h--margin-06 h6 {
  margin-bottom: 0.6rem;
}

.h--margin-07 .alfa,
.h--margin-07 .beta,
.h--margin-07 .gamma,
.h--margin-07 .delta,
.h--margin-07 .epsilon,
.h--margin-07 .zeta,
.h--margin-07 h1,
.h--margin-07 h2,
.h--margin-07 h3,
.h--margin-07 h4,
.h--margin-07 h5,
.h--margin-07 h6 {
  margin-bottom: 0.7rem;
}

.h--margin-08 .alfa,
.h--margin-08 .beta,
.h--margin-08 .gamma,
.h--margin-08 .delta,
.h--margin-08 .epsilon,
.h--margin-08 .zeta,
.h--margin-08 h1,
.h--margin-08 h2,
.h--margin-08 h3,
.h--margin-08 h4,
.h--margin-08 h5,
.h--margin-08 h6 {
  margin-bottom: 0.8rem;
}

.h--margin-09 .alfa,
.h--margin-09 .beta,
.h--margin-09 .gamma,
.h--margin-09 .delta,
.h--margin-09 .epsilon,
.h--margin-09 .zeta,
.h--margin-09 h1,
.h--margin-09 h2,
.h--margin-09 h3,
.h--margin-09 h4,
.h--margin-09 h5,
.h--margin-09 h6 {
  margin-bottom: 0.9rem;
}

.h--margin-1 .alfa,
.h--margin-1 .beta,
.h--margin-1 .gamma,
.h--margin-1 .delta,
.h--margin-1 .epsilon,
.h--margin-1 .zeta,
.h--margin-1 h1,
.h--margin-1 h2,
.h--margin-1 h3,
.h--margin-1 h4,
.h--margin-1 h5,
.h--margin-1 h6 {
  margin-bottom: 1rem;
}

.h--margin-1-2 .alfa,
.h--margin-1-2 .beta,
.h--margin-1-2 .gamma,
.h--margin-1-2 .delta,
.h--margin-1-2 .epsilon,
.h--margin-1-2 .zeta,
.h--margin-1-2 h1,
.h--margin-1-2 h2,
.h--margin-1-2 h3,
.h--margin-1-2 h4,
.h--margin-1-2 h5,
.h--margin-1-2 h6 {
  margin-bottom: 1.2rem;
}

.h--margin-1-5 .alfa,
.h--margin-1-5 .beta,
.h--margin-1-5 .gamma,
.h--margin-1-5 .delta,
.h--margin-1-5 .epsilon,
.h--margin-1-5 .zeta,
.h--margin-1-5 h1,
.h--margin-1-5 h2,
.h--margin-1-5 h3,
.h--margin-1-5 h4,
.h--margin-1-5 h5,
.h--margin-1-5 h6 {
  margin-bottom: 1.5rem;
}

.h--margin-2 .alfa,
.h--margin-2 .beta,
.h--margin-2 .gamma,
.h--margin-2 .delta,
.h--margin-2 .epsilon,
.h--margin-2 .zeta,
.h--margin-2 h1,
.h--margin-2 h2,
.h--margin-2 h3,
.h--margin-2 h4,
.h--margin-2 h5,
.h--margin-2 h6 {
  margin-bottom: 2rem;
}

.h--margin-2-5 .alfa,
.h--margin-2-5 .beta,
.h--margin-2-5 .gamma,
.h--margin-2-5 .delta,
.h--margin-2-5 .epsilon,
.h--margin-2-5 .zeta,
.h--margin-2-5 h1,
.h--margin-2-5 h2,
.h--margin-2-5 h3,
.h--margin-2-5 h4,
.h--margin-2-5 h5,
.h--margin-2-5 h6 {
  margin-bottom: 2.5rem;
}

.h--margin-3 .alfa,
.h--margin-3 .beta,
.h--margin-3 .gamma,
.h--margin-3 .delta,
.h--margin-3 .epsilon,
.h--margin-3 .zeta,
.h--margin-3 h1,
.h--margin-3 h2,
.h--margin-3 h3,
.h--margin-3 h4,
.h--margin-3 h5,
.h--margin-3 h6 {
  margin-bottom: 3rem;
}

.h--headline-divider .alfa,
.h--headline-divider .beta,
.h--headline-divider .gamma,
.h--headline-divider .delta,
.h--headline-divider .epsilon,
.h--headline-divider .zeta,
.h--headline-divider h1,
.h--headline-divider h2,
.h--headline-divider h3,
.h--headline-divider h4,
.h--headline-divider h5,
.h--headline-divider h6 {
  position: relative;
}

.h--headline-divider .alfa:after,
.h--headline-divider .beta:after,
.h--headline-divider .gamma:after,
.h--headline-divider .delta:after,
.h--headline-divider .epsilon:after,
.h--headline-divider .zeta:after,
.h--headline-divider h1:after,
.h--headline-divider h2:after,
.h--headline-divider h3:after,
.h--headline-divider h4:after,
.h--headline-divider h5:after,
.h--headline-divider h6:after {
  content: '';
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 150px;
  height: 2px;
  background-color: #ff5c53;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  outline: none;
  font-family: "Gilroy-Regular", Arial, sans-serif;
}

a:focus,
a:active,
a:visited {
  outline: none;
}

.a-hover:hover, .a-hover:focus {
  text-decoration: underline;
}

.a-link {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 0.4rem 0;
}

.a-link__text {
  color: #030303;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
}

.a-link__text--black {
  color: #ff5c53;
}

.a-link__text--white {
  color: #ffffff;
}

.a-link__text--red {
  color: #ff5c53;
}

.a-link__text--size-12 {
  font-size: 12px;
  font-size: 0.75rem;
}

.a-link__text--size-14 {
  font-size: 14px;
  font-size: 0.875rem;
}

.a-link__text--size-16 {
  font-size: 16px;
  font-size: 1rem;
}

.a-link__text--size-18 {
  font-size: 18px;
  font-size: 1.125rem;
}

.a-link__icon {
  display: inline-flex;
  margin-left: 1rem;
  align-items: center;
}

.a-link--hover-red:hover {
  color: #ff5c53;
}

.a-link--hover-underline {
  position: relative;
}

.a-link--hover-underline:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 0;
  height: 1px;
  transition: width 250ms ease-out;
  background-color: currentColor;
}

.a-link--hover-underline:hover:after {
  width: 100%;
}

.a-link--hover-block .a-link--hover-underline {
  position: relative;
}

.a-link--hover-block .a-link--hover-underline:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 0;
  height: 1px;
  transition: width 250ms ease-out;
  background-color: currentColor;
}

.a-link--hover-block:hover .a-link--hover-underline:after {
  width: 100%;
}

p {
  display: block;
  margin-top: 0;
  margin-bottom: 2rem;
  color: #030303;
  font-family: "Gilroy-Light", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.4;
  transition: font-size 0.3s cubic-bezier(0.16, 0.08, 0.355, 1);
  font-size: 14px;
  font-size: 0.875rem;
}

p a {
  color: currentColor;
}

.p-headline {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  display: inline-block;
  background-color: #ffffff;
  padding: 0 1.5rem;
  min-width: 220px;
}

.p--size-12 p {
  font-size: 12px;
  font-size: 0.75rem;
}

.p--size-14 p {
  font-size: 14px;
  font-size: 0.875rem;
}

.p--size-18 p {
  font-size: 18px;
  font-size: 1.125rem;
}

.p--size-20 p {
  font-size: 20px;
  font-size: 1.25rem;
}

@media (min-width: 576px) {
  .p--size-20 p {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.p--size-24 p {
  font-size: 20px;
  font-size: 1.25rem;
}

@media (min-width: 576px) {
  .p--size-24 p {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.p--weight-400 p {
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
}

.p--line-1 p {
  line-height: 1;
}

.p--line-1-2 p {
  line-height: 1.2;
}

.p--line-1-4 p {
  line-height: 1.4;
}

.p--line-1-5 p {
  line-height: 1.5;
}

.p--line-1-6 p {
  line-height: 1.6;
}

.p--line-1-8 p {
  line-height: 1.8;
}

.p--line-2 p {
  line-height: 2;
}

.p--margin-0 p {
  margin-bottom: 0;
}

.p--margin-01 p {
  margin-bottom: 0.1rem;
}

.p--margin-02 p {
  margin-bottom: 0.2rem;
}

.p--margin-03 p {
  margin-bottom: 0.3rem;
}

.p--margin-04 p {
  margin-bottom: 0.4rem;
}

.p--margin-05 p {
  margin-bottom: 0.5rem;
}

.p--margin-06 p {
  margin-bottom: 0.6rem;
}

.p--margin-07 p {
  margin-bottom: 0.7rem;
}

.p--margin-08 p {
  margin-bottom: 0.8rem;
}

.p--margin-09 p {
  margin-bottom: 0.9rem;
}

.p--margin-1 p {
  margin-bottom: 1rem;
}

.p--margin-1-2 p {
  margin-bottom: 1.2rem;
}

.p--margin-1-5 p {
  margin-bottom: 1.5rem;
}

.p--margin-2 p {
  margin-bottom: 2rem;
}

.p--margin-2-5 p {
  margin-bottom: 2.5rem;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.list-style,
ol.list-style {
  margin-bottom: 2rem;
}

ul.list-style li,
ol.list-style li {
  position: relative;
  display: block;
  margin-bottom: 8px;
  padding-left: 25px;
  color: #000000;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.4;
  font-size: 16px;
  font-size: 1rem;
}

ul.list-style a,
ol.list-style a {
  text-decoration: underline;
}

ul.list-style a:focus, ul.list-style a:hover,
ol.list-style a:focus,
ol.list-style a:hover {
  text-decoration: underline;
}

ul.list-style li:before {
  content: '';
  position: absolute;
  top: 9px;
  left: 3px;
  display: block;
  width: 6px;
  height: 6px;
  color: #000000;
  border-radius: 100%;
  background-color: #000000;
}

ol.list-style {
  list-style-position: inside;
  counter-reset: item;
}

ol.list-style li:before {
  content: counter(item) ".";
  counter-increment: item;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  color: #000000;
  line-height: 1.4;
  font-size: 16px;
  font-size: 1rem;
}

.swiper-arrow {
  position: absolute;
  top: 90px;
  outline: none;
  z-index: 6;
}

.swiper-arrow.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.swiper-arrow-next {
  right: 0;
}

@media (min-width: 771px) {
  .swiper-arrow-next {
    right: -15px;
  }
}

.swiper-arrow-prev {
  left: 0;
}

@media (min-width: 771px) {
  .swiper-arrow-prev {
    left: -15px;
  }
}

.swiper-arrow-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 100%;
  transition: background-color 250ms ease-out;
}

@media (min-width: 771px) {
  .swiper-arrow-content {
    height: 65px;
    width: 65px;
  }
}

.swiper-arrow-content:focus, .swiper-arrow-content:hover {
  background-color: #ff5c53;
}

.swiper-arrow-content:focus svg, .swiper-arrow-content:hover svg {
  fill: #ffffff;
}

.swiper-arrow-content svg {
  fill: #000000;
  font-size: 24px;
  font-size: 1.5rem;
}

.select2-container {
  z-index: 9;
}

.select2-container.select2-container--open .select2-selection--single .select2-selection__arrow:after,
.select2-container.select2-container--open .select2-selection--multiple .select2-selection__arrow:after,
.select2-container .select2-container--focus .select2-selection--single .select2-selection__arrow:after,
.select2-container .select2-container--focus .select2-selection--multiple .select2-selection__arrow:after {
  background-image: url("../images/icons/chevron-up.svg");
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0;
}

.select2-container .select2-search--inline {
  display: block;
  width: 100%;
}

.select2-container .select2-selection--single,
.select2-container .select2-selection--multiple {
  width: 100%;
  width: 100%;
  height: 50px;
  padding: 0 40px 1px 1rem;
  padding-bottom: 1px;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0px;
  color: #000000;
  border: 0;
  outline: none !important;
  background-color: #ffffff;
  font-size: 14px;
  font-size: 0.875rem;
  border-radius: 0;
}

@media (min-width: 2000px) {
  .select2-container .select2-selection--single,
  .select2-container .select2-selection--multiple {
    min-height: 60px;
  }
}

.select2-container .select2-selection--single .select2-selection__rendered,
.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
  line-height: 50px;
  color: #000000;
}

.select2-container .select2-selection--single .select2-selection__placeholder,
.select2-container .select2-selection--multiple .select2-selection__placeholder {
  color: rgba(25, 25, 25, 0.3);
}

.select2-container .select2-selection--single .select2-selection__arrow,
.select2-container .select2-selection--multiple .select2-selection__arrow {
  position: absolute;
  top: 0;
  right: 0px;
  width: 35px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.select2-container .select2-selection--single .select2-selection__arrow:after,
.select2-container .select2-selection--multiple .select2-selection__arrow:after {
  content: '' !important;
  width: 11px;
  height: 6px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/icons/chevron-down.svg");
}

.select2-container .select2-selection--single .select2-selection__arrow b,
.select2-container .select2-selection--multiple .select2-selection__arrow b {
  display: none;
}

.select2-container .select2-container--focus .select2-selection--single .select2-selection__arrow:after,
.select2-container.select2-container--open .select2-selection--single .select2-selection__arrow:after {
  content: '\e91a';
}

.select2-dropdown {
  border: 0;
  border-radius: 0;
  background-color: white;
}

.select2-dropdown .select2-results__options--nested .select2-results__option {
  padding: 0 1rem;
}

.select2-dropdown .select2-results__options {
  max-height: 220px !important;
}

.select2-dropdown .select2-results__options .select2-results__option[aria-selected='true'] {
  color: #000000;
  background-color: #ffbdb9;
}

.select2-dropdown .select2-results__options .select2-results__option {
  padding: 0.6rem 1rem;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  font-size: 14px;
  font-size: 0.875rem;
}

.select2-dropdown .select2-results__options .select2-results__option--highlighted {
  color: #000000;
  background-color: #ffbdb9;
}

.select-medium-dropdown.select2-dropdown .select2-results__options .select2-results__option {
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 700;
  padding: 18px 15px;
  font-size: 14px;
  font-size: 0.875rem;
}

/* Content */
section {
  position: relative;
  padding: 3rem 0 3rem;
}

@media (min-width: 771px) {
  section {
    padding: 3rem 0 3rem;
  }
}

.section-divider-bottom:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  width: 150px;
  height: 2px;
  background-color: #ff5c53;
  transform: translateX(-50%);
}

.padding-style-1 {
  padding: 1rem 0 1rem;
}

@media (min-width: 771px) {
  .padding-style-1 {
    padding: 1rem 0 1rem;
  }
}

.padding-style-2 {
  padding: 2rem 0 2rem;
}

@media (min-width: 771px) {
  .padding-style-2 {
    padding: 2rem 0 2rem;
  }
}

.padding-style-3 {
  padding: 2rem 0 2rem;
}

@media (min-width: 771px) {
  .padding-style-3 {
    padding: 3rem 0 3rem;
  }
}

.padding-style-3-8 {
  padding: 0 0 4rem;
}

@media (min-width: 771px) {
  .padding-style-3-8 {
    padding: 3rem 0 8rem;
  }
}

.padding-style-4 {
  padding: 3rem 0 3rem;
}

@media (min-width: 771px) {
  .padding-style-4 {
    padding: 4rem 0 4rem;
  }
}

.padding-style-5 {
  padding: 3rem 0 3rem;
}

@media (min-width: 771px) {
  .padding-style-5 {
    padding: 5rem 0 5rem;
  }
}

.padding-style-6-22 {
  padding: 4rem 0.5rem 3rem;
}

@media (min-width: 771px) {
  .padding-style-6-22 {
    padding: 6rem 0 22rem;
  }
}

.padding-style-6-3 {
  padding: 4rem 0.5rem 3rem;
}

@media (min-width: 771px) {
  .padding-style-6-3 {
    padding: 6rem 0 3rem;
  }
}

.padding-style-6-6 {
  padding: 4rem 0.5rem 3rem;
}

@media (min-width: 771px) {
  .padding-style-6-6 {
    padding: 6rem 0 2rem;
  }
}

@media (min-width: 992px) {
  .padding-style-6-6 {
    padding: 6rem 0 6rem;
  }
}

.padding-style-6-6-ext {
  padding: 4rem 0.5rem 3rem;
}

@media (min-width: 992px) {
  .padding-style-6-6-ext {
    padding: 6rem 0 6rem;
  }
}

.padding-style-6-4 {
  padding: 4rem 1.5rem 4rem;
}

@media (min-width: 771px) {
  .padding-style-6-4 {
    padding: 6rem 1rem 4rem;
  }
}

@media (min-width: 992px) {
  .padding-style-6-4 {
    padding: 6rem 1rem 4rem;
  }
}

.padding-style-6-5 {
  padding: 5rem 1.5rem 4rem;
}

@media (min-width: 771px) {
  .padding-style-6-5 {
    padding: 6rem 1rem 5rem;
  }
}

.padding-style-6-5-ext {
  padding: 5rem 1rem 2rem;
}

@media (min-width: 992px) {
  .padding-style-6-5-ext {
    padding: 6rem 1rem 5rem;
  }
}

.padding-style-6-8 {
  padding: 2rem 0.5rem 2rem;
}

@media (min-width: 771px) {
  .padding-style-6-8 {
    padding: 6rem 0 8rem;
  }
}

.padding-style-6-9 {
  padding: 5rem 1.5rem 4rem;
}

@media (min-width: 771px) {
  .padding-style-6-9 {
    padding: 6rem 0 9rem;
  }
}

.padding-style-6-10 {
  padding: 4rem 0.5rem 6rem;
}

@media (min-width: 771px) {
  .padding-style-6-10 {
    padding: 6rem 0 10rem;
  }
}

.padding-style-6 {
  padding: 3rem 0 3rem;
}

@media (min-width: 771px) {
  .padding-style-6 {
    padding: 6rem 0 6rem;
  }
}

.padding-style-7 {
  padding: 5rem 1rem 4rem;
}

@media (min-width: 992px) {
  .padding-style-7 {
    padding: 7rem 0 7rem;
  }
}

.padding-style-8 {
  padding: 3rem 0 3rem;
}

@media (min-width: 771px) {
  .padding-style-8 {
    padding: 8rem 0 8rem;
  }
}

.padding-style-8-10 {
  padding: 3rem 0 3rem;
}

@media (min-width: 992px) {
  .padding-style-8-10 {
    padding: 8rem 0 10rem;
  }
}

.padding-style-10 {
  padding: 4rem 0.5rem 4rem;
}

@media (min-width: 771px) {
  .padding-style-10 {
    padding: 6rem 0 6rem;
  }
}

@media (min-width: 992px) {
  .padding-style-10 {
    padding: 10rem 0 10rem;
  }
}

.padding-style-10-9 {
  padding: 6rem 0.5rem 3rem;
}

@media (min-width: 771px) {
  .padding-style-10-9 {
    padding: 10rem 0 4rem;
  }
}

@media (min-width: 992px) {
  .padding-style-10-9 {
    padding: 10rem 0 9rem;
  }
}

.padding-style-10-10 {
  padding: 6rem 0.5rem 6rem;
}

@media (min-width: 771px) {
  .padding-style-10-10 {
    padding: 10rem 0 10rem;
  }
}

.padding-style-10-10-ext {
  padding: 2rem 0.5rem 6rem;
}

@media (min-width: 771px) {
  .padding-style-10-10-ext {
    padding: 4rem 0 8rem;
  }
}

@media (min-width: 992px) {
  .padding-style-10-10-ext {
    padding: 10rem 0 10rem;
  }
}

.padding-style-10-13 {
  padding: 6rem 0.5rem 4rem;
}

@media (min-width: 771px) {
  .padding-style-10-13 {
    padding: 10rem 0.5rem 6rem;
  }
}

@media (min-width: 992px) {
  .padding-style-10-13 {
    padding: 10rem 0 13rem;
  }
}

.padding-content-1 {
  padding: 1rem 1rem;
}

.padding-content-2 {
  padding: 2rem 2rem;
}

.padding-content-3-0 {
  padding: 3rem 0;
}

.padding-content-4 {
  padding: 4rem 4rem;
}

.padding-content-5 {
  padding: 5rem 5rem;
}

.padding-content-6-0 {
  padding: 4rem 0;
}

@media (min-width: 771px) {
  .padding-content-6-0 {
    padding: 6rem 0;
  }
}

.padding-content-9-0 {
  padding: 4rem 0;
}

@media (min-width: 771px) {
  .padding-content-9-0 {
    padding: 9rem 2rem 9rem 0;
  }
}

@media (min-width: 992px) {
  .padding-content-9-0 {
    padding: 9rem 5rem 9rem 0;
  }
}

@media (min-width: 771px) {
  .padding-content-2 {
    padding: 2rem 2rem;
  }
}

.content-width-200 {
  max-width: 200px;
}

.content-width-300 {
  max-width: 300px;
}

.content-width-400 {
  max-width: 400px;
}

.content-width-450 {
  max-width: 450px;
}

.content-width-500 {
  max-width: 500px;
}

.content-width-600 {
  max-width: 600px;
}

.content-width-700 {
  max-width: 700px;
}

.content-width-800 {
  max-width: 800px;
}

@media (min-width: 771px) {
  .content-width-sm-450 {
    max-width: 450px;
  }
}

.page-index main {
  padding-top: 0;
}

main {
  position: relative;
  display: block;
  padding-top: 140px;
  flex: 1 0 auto;
}

@media (min-width: 771px) {
  main {
    padding-top: 120px;
  }
}

.container-full {
  margin: 0 auto;
}

.container-small,
.container-normal,
.container-nav,
.container-large {
  margin: 0 auto;
  padding: 0 15px;
}

.container-small {
  width: 100%;
}

@media (min-width: 771px) {
  .container-small {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .container-small {
    width: 900px;
  }
}

@media (min-width: 1200px) {
  .container-small {
    width: 900px;
  }
}

@media (min-width: 1440px) {
  .container-small {
    width: 900px;
  }
}

@media (min-width: 2000px) {
  .container-small {
    width: 1400px;
  }
}

.container-normal {
  width: 100%;
}

@media (min-width: 771px) {
  .container-normal {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-normal {
    width: 950px;
  }
}

@media (min-width: 1200px) {
  .container-normal {
    width: 1130px;
  }
}

@media (min-width: 1440px) {
  .container-normal {
    width: 1130px;
  }
}

@media (min-width: 2000px) {
  .container-normal {
    width: 1800px;
  }
}

.container-nav {
  width: 100%;
}

@media (min-width: 771px) {
  .container-nav {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-nav {
    width: 950px;
  }
}

@media (min-width: 1200px) {
  .container-nav {
    width: 1000px;
  }
}

@media (min-width: 1440px) {
  .container-nav {
    width: 1000px;
  }
}

@media (min-width: 2000px) {
  .container-nav {
    width: 1500px;
  }
}

.container-large {
  width: 100%;
}

@media (min-width: 771px) {
  .container-large {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-large {
    width: 950px;
  }
}

@media (min-width: 1200px) {
  .container-large {
    width: 1140px;
  }
}

@media (min-width: 1440px) {
  .container-large {
    width: 1360px;
  }
}

/* Footer */
.footer {
  position: relative;
  z-index: 2;
  padding: 4.5rem 0.5rem 3rem;
  background-color: #000000;
  flex-shrink: 0;
}

.footer__title {
  color: #ffffff;
  font-size: 18px;
  font-size: 1.125rem;
  font-family: "Gilroy-Medium", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.6rem;
}

.footer__title--small {
  font-size: 14px;
  font-size: 0.875rem;
}

.footer__copy {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.6;
}

.footer__list {
  column-count: 2;
}

@media (min-width: 576px) {
  .footer__list {
    column-count: 3;
  }
}

.footer__item {
  display: block;
  margin-bottom: 0.2rem;
}

.footer__link {
  display: inline-block;
  line-height: 1.4;
  color: #ffffff;
  padding: 0.3rem 0;
  font-size: 12px;
  font-size: 0.75rem;
}

@media (min-width: 771px) {
  .footer__link {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

/* Header */
.header__navigation .main-nav {
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  background-color: #ffffff;
}

@media (max-width: 770px) {
  .header__navigation .main-nav {
    display: flex;
    align-items: center;
  }
}

.header__navigation .container-nav {
  transform: scale(0.8);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  will-change: transform;
}

.header__navigation:not(.is-collapse) .header__hamburger:hover .header__hamburger-icon::after {
  margin-top: 13px;
}

.header__navigation:not(.is-collapse) .header__hamburger:hover .header__hamburger-icon::before {
  margin-top: -13px;
}

.header__navigation .header__hamburger {
  position: absolute;
  z-index: 200;
  right: 0;
  display: block;
  width: 60px;
  height: 60px;
  cursor: pointer;
  top: 30px;
}

@media (min-width: 771px) {
  .header__navigation .header__hamburger {
    top: 45px;
  }
}

.header__navigation .header__hamburger-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 40px;
  height: 3px;
  transition-duration: 250ms;
  transition-property: background-color, transform;
  transform: translate(-50%, -50%);
  background-color: #ff5c53;
  font-weight: 300;
  border-radius: 5px;
}

.header__navigation .header__hamburger-icon:before, .header__navigation .header__hamburger-icon:after {
  content: '';
  position: absolute;
  display: block;
  width: 20px;
  height: 3px;
  transition-duration: 250ms;
  border-radius: 5px;
  transition-property: margin, transform;
  background: #ff5c53;
}

.header__navigation .header__hamburger-icon:before {
  margin-top: -10px;
  right: 0;
}

.header__navigation .header__hamburger-icon:after {
  margin-top: 10px;
}

.header__navigation.is-collapse.header__navigation {
  position: fixed;
}

.header__navigation.is-collapse .main-nav {
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  overflow: auto;
  padding-top: 120px;
  padding-bottom: 50px;
}

@media (min-width: 771px) {
  .header__navigation.is-collapse .main-nav {
    padding-top: 150px;
    padding-bottom: 100px;
  }
}

.header__navigation.is-collapse .main-nav .container-nav {
  transform: scale(1);
}

.header__navigation.is-collapse .header__hamburger-icon {
  transition: none;
  background: rgba(0, 0, 0, 0);
}

.header__navigation.is-collapse .header__hamburger-icon:before {
  width: 40px;
  background-color: #ff5c53;
  margin-top: 0;
  transform: rotate(45deg);
}

.header__navigation.is-collapse .header__hamburger-icon:after {
  width: 40px;
  background-color: #ff5c53;
  margin-top: 0;
  transform: rotate(-45deg);
}

.page-index .header__navigation:not(.is-collapse) .header__logo-link svg {
  fill: #ffffff;
}

.page-index .header__navigation:not(.is-collapse) .header__hamburger-icon {
  background-color: #ffffff;
}

.page-index .header__navigation:not(.is-collapse) .header__hamburger-icon:before, .page-index .header__navigation:not(.is-collapse) .header__hamburger-icon:after {
  background: #ffffff;
}

.header__navigation {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 15px;
}

@media (min-width: 992px) {
  .header__navigation {
    padding: 0 30px;
    box-shadow: none;
  }
}

@media (min-width: 992px) {
  .header__navigation.is-scrolling .header__logo-link svg {
    fill: #000000;
  }
}

@media (min-width: 992px) {
  .header__flex {
    display: flex;
    justify-content: space-between;
  }
}

.header__logo {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 99;
  text-align: center;
  top: 30px;
}

@media (min-width: 771px) {
  .header__logo {
    top: 50px;
  }
}

.header__logo-link {
  display: inline-block;
  vertical-align: middle;
}

.header__logo-link svg {
  fill: #000000;
  font-size: 50px;
  font-size: 3.125rem;
}

.main-nav {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: opacity 250ms ease-out;
  will-change: opacity;
}

.main-nav__item {
  text-align: center;
}

@media (min-width: 771px) {
  .main-nav__item {
    text-align: left;
    margin-bottom: 2.1875rem;
  }
  .main-nav__item:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }
}

@media (max-width: 770px) {
  .main-nav__item:last-child {
    margin-bottom: 0;
  }
}

.main-nav__link {
  padding: 0.5rem 0;
}

.main-nav__link:hover {
  color: #ff5c53;
}

/* Social block */
.social {
  margin-left: -0.5rem;
}

.social__item {
  display: inline-block;
  margin-right: 0.3rem;
  margin-bottom: 0.5rem;
  vertical-align: middle;
}

.social__item:last-child {
  margin-right: 0;
}

.social__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
}

.social__link svg {
  font-size: 29px;
  font-size: 1.8125rem;
}

.social__link:focus svg, .social__link:hover svg {
  fill: #ff5c53;
}

/* language block */
.language {
  margin-right: -0.5rem;
}

.language__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  font-family: "Gilroy-Light", Arial, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.language__link:after {
  content: '';
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  height: 3px;
  width: 0;
  transition: width 250ms ease-out;
  background-color: #ff5c53;
}

.language__link:focus:after, .language__link:hover:after {
  width: 30px;
}

.language__link.is-active {
  font-family: "Gilroy-Medium", Arial, sans-serif;
  font-weight: 500;
}

.language__link.is-active:after {
  width: 30px;
}

/* Map block */
.map {
  width: 100%;
  height: 400px;
  background-color: #000000;
  position: relative;
}

@media (min-width: 771px) {
  .map {
    height: 500px;
  }
}

/* Modal block */
.modal__overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  transition: opacity 400ms ease;
}

.modal__overlay.is-active {
  width: 100%;
  height: 100%;
  opacity: 1;
  overflow: visible;
  visibility: visible;
}

.modal {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  width: auto;
  height: 0;
  padding: 3rem 0 2rem;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: center;
  transition: opacity 400ms ease;
}

@media (min-width: 771px) {
  .modal {
    padding: 4rem 0 4rem;
  }
}

.modal.is-active {
  visibility: visible;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 400ms ease;
}

.modal.is-active .modal__box {
  position: relative;
  top: auto;
  left: auto;
  transform: translate(0, 0);
}

.modal.modal__hidden .modal__close,
.modal.modal__hidden .modal__scroll {
  display: none;
}

.modal__scroll {
  width: 100%;
  margin: auto 1rem;
}

.modal__box {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

@media (min-width: 771px) {
  .modal__box {
    width: 600px;
  }
}

.modal__close {
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  display: flex;
  width: 50px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.modal__close svg {
  position: relative;
  transform: rotateZ(-45deg);
  fill: #ff5c53;
  font-size: 22px;
  font-size: 1.375rem;
}

.modal__close:focus, .modal__close:hover {
  background-color: #ff5c53;
}

.modal__close:focus svg, .modal__close:hover svg {
  fill: #ffffff;
}

.modal__body {
  box-shadow: 0 10px 30px rgba(175, 175, 175, 0.5);
  background-color: #ffffff;
  padding: 3.75rem 1.25rem 2.1875rem;
}

@media (min-width: 771px) {
  .modal__body {
    padding: 3.75rem 2.5rem 2.1875rem;
  }
}

.file-upload__input {
  position: absolute;
  z-index: -1;
  overflow: hidden;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}

.file-upload__input + .file-upload__label {
  display: inline-block;
  overflow: hidden;
  margin-bottom: 0;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.file-upload__label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.file-upload__label:hover .file-upload__text {
  color: #ec0c00;
}

.file-upload__label svg {
  position: relative;
  top: 3px;
}

.file-upload__text {
  text-decoration: underline;
  color: #ff5c53;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
}

.file-upload__output {
  display: block;
  margin-top: 10px;
  line-height: 1;
  color: #000000;
  font-size: 14px;
  font-size: 0.875rem;
}

.file-upload__icon {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
  vertical-align: middle;
  top: -2px;
}

.file-upload__icon:before, .file-upload__icon:after {
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  content: '';
  background: #000;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -1px;
}

.file-upload__icon:before {
  transform: rotate(45deg);
}

.file-upload__icon:after {
  transform: rotate(-45deg);
}

/* Blog block */
.article__item {
  margin-bottom: 1.875rem;
}

.article__item--side {
  margin-bottom: 1.5625rem;
}

.article__item--side .blog__content {
  display: flex;
}

.article__item--side .blog__text-block {
  flex: 1;
  padding-left: 1.5625rem;
}

.article__item--side .blog__title {
  text-decoration: underline;
  font-size: 16px;
  font-size: 1rem;
  font-family: "Gilroy-Light", Arial, sans-serif;
  font-weight: 300;
}

.article__item--side .blog__item {
  margin-bottom: 0;
}

.blog__content {
  display: block;
  overflow: hidden;
  background-color: #ffffff;
}

.blog__content:focus, .blog__content:hover {
  color: #000000;
}

.blog__content:focus .blog__title, .blog__content:hover .blog__title {
  color: #ff5c53;
}

.blog__content:focus img, .blog__content:hover img {
  transform: scale(1.05, 1.05);
}

.blog__content:focus .btn-text:after, .blog__content:hover .btn-text:after {
  height: 100%;
  transform: translate(-50%, 6px);
}

.blog__content:focus .btn-icon, .blog__content:hover .btn-icon {
  transform: translateX(5px);
}

.blog__content .btn-gray-outline {
  margin-left: 0.3rem;
  margin-top: -0.3rem;
}

.blog__image-block {
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
}

.blog__image-block img {
  transition: transform 400ms ease-out;
  will-change: transform;
}

.blog__number-block {
  position: relative;
  top: -5px;
  width: 30px;
  max-width: 30px;
  flex: 0 0 30px;
  color: #f8f8f8;
  font-family: "Blacker-Pro-ExBd", Arial, sans-serif;
  font-size: 54px;
  font-size: 3.375rem;
  font-weight: 700;
  line-height: 1;
}

.blog__text-block p {
  margin-bottom: 2rem;
}

.blog__item {
  position: relative;
  display: inline-flex;
  margin-bottom: 0.3rem;
  color: #afafaf;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
}

.blog__item:not(:last-child) {
  padding-right: 1.2rem;
}

.blog__item:not(:last-child):after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0.5rem;
  display: block;
  width: 4px;
  height: 4px;
  background-color: #afafaf;
  border-radius: 100%;
  transform: translateY(-50%);
}

.blog__item--big {
  font-size: 16px;
  font-size: 1rem;
}

@media (min-width: 771px) {
  .blog__item--big {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.blog__item--black {
  color: #000000;
}

.blog__item--highlighted {
  color: #ff5c53;
  text-transform: uppercase;
}

.blog__title {
  margin-bottom: 1rem;
}

@media (min-width: 1200px) {
  .blog-fixed {
    position: fixed;
    right: calc(((100vw - 900px) / 2) - 150px);
    top: 50%;
    max-width: 140px;
    margin-top: -80px;
  }
}

@media (min-width: 1440px) {
  .blog-fixed {
    right: calc(((100vw - 900px) / 2) - 180px);
  }
}

@media (min-width: 2000px) {
  .blog-fixed {
    right: calc(((100vw - 1400px) / 2) - 180px);
  }
}

.pagination-list__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  line-height: 50px;
  color: #ff5c53;
  font-size: 24px;
  font-size: 1.5rem;
  cursor: pointer;
}

.pagination-list__icon:focus svg, .pagination-list__icon:hover svg {
  fill: #ff2c20;
}

.pagination-list__icon svg {
  fill: #ff5c53;
}

.pagination-list__icon.is-disabled {
  opacity: 0.2;
}

.pagination-list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0;
  text-align: center;
}

.pagination-list__item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pagination-list__item:focus .pagination-list__link, .pagination-list__item:hover .pagination-list__link, .pagination-list__item.is-active .pagination-list__link {
  font-family: "Gilroy-Bold", Arial, sans-serif;
  font-weight: 700;
}

.pagination-list__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #000000;
  border-radius: 100%;
  font-family: "Gilroy-Light", Arial, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 24px;
  font-size: 1.5rem;
}

.hero-section {
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: #000000;
  padding: 0 0.5rem;
}

.hero-arrow {
  position: absolute;
  bottom: 20px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  transform: translateX(-50%);
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
  cursor: pointer;
}

.hero-arrow svg {
  fill: #ff5c53;
  font-size: 30px;
  font-size: 1.875rem;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translate(-50%, 0);
  }
  40% {
    transform: translate(-50%, -30px);
  }
  60% {
    transform: translate(-50%, -15px);
  }
}

.team-image-group {
  display: block;
  margin-left: -1rem;
}

.team__item {
  margin-bottom: 5rem;
}

@media (max-width: 369px) {
  .team__item {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (min-width: 771px) {
  .team__item {
    margin-bottom: 8.75rem;
  }
}

@media (min-width: 576px) {
  .team__item--main {
    margin-bottom: 5.375rem;
  }
}

@media (min-width: 576px) {
  .team__item--main .team__content {
    display: flex;
    align-items: center;
  }
  .team__item--main .team__content .team__image-block {
    width: 250px;
    max-width: 250px;
    flex: 0 0 250px;
    margin-bottom: 0;
  }
  .team__item--main .team__content .team__text-block {
    padding-left: 1.25rem;
    width: calc(100% - 250px);
    max-width: calc(100% - 250px);
    flex: 0 0 calc(100% - 250px);
    text-align: left;
  }
  .team__item--main .team__content .team__name {
    font-size: 28px;
    font-size: 1.75rem;
  }
  .team__item--main .team__content .team__description {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .team__item--main .team__content p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (min-width: 771px) {
  .team__item--main .team__content .team__image-block {
    width: 300px;
    max-width: 300px;
    flex: 0 0 300px;
    margin-bottom: 0;
  }
  .team__item--main .team__content .team__text-block {
    padding-left: 2.625rem;
    width: calc(100% - 300px);
    max-width: calc(100% - 300px);
    flex: 0 0 calc(100% - 300px);
  }
}

.team__content:focus .team__overlay, .team__content:hover .team__overlay {
  opacity: 1;
}

.team__content:focus .team__contact-link, .team__content:hover .team__contact-link {
  transform: scale(1);
}

.team__content:focus .team__contact-link:hover, .team__content:hover .team__contact-link:hover {
  transform: scale(1.2);
}

.team__content--hire:hover .team__image-block {
  border-color: #ff7d75;
  border-style: solid;
  background-color: #ff7d75;
}

.team__content--hire:hover .team__image-block svg {
  fill: #ffffff;
}

.team__content--hire:hover .team__image-block .team__hire-text {
  color: #ffffff;
}

.team__image-block {
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 100%;
  margin-bottom: 2.75rem;
  background-color: #f9f9f9;
}

.team__image-block--border {
  border: 2px dashed #dedede;
  background-color: transparent;
}

.team__overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(255, 92, 83, 0.8);
  transition: opacity 250ms ease-out;
}

.team__hire {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: opacity 250ms ease-out;
}

.team__hire-text {
  font-size: 18px;
  font-size: 1.125rem;
  color: #000000;
}

.team__contact-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 10px;
  transform: scale(0);
  transition: transform 250ms ease-out;
}

.team__contact-link svg {
  font-size: 28px;
  font-size: 1.75rem;
  fill: #ffffff;
}

@media (max-width: 575px) {
  .team__text-block p {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.team__position {
  display: block;
  margin-bottom: 0.8rem;
  color: #9b9b9b;
  line-height: 1.2;
  font-size: 14px;
  font-size: 0.875rem;
}

.job-list__item {
  position: relative;
  display: inline-flex;
  align-items: center;
  position: relative;
  color: #afafaf;
  margin-right: 1.2rem;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-size: 0.875rem;
}

.job-list__item:not(:last-child):after {
  content: '';
  position: absolute;
  top: 50%;
  right: -0.8rem;
  display: block;
  width: 4px;
  height: 4px;
  background-color: #afafaf;
  border-radius: 100%;
  transform: translateY(-50%);
}

.clip-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.clip-text-gray {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#32373a), color-stop(85%, rgba(30, 36, 29, 0)));
  background-image: linear-gradient(180deg, #f4f4f4 0%, #ffffff 100%);
  color: #f4f4f4 !important;
}

.no-background-clip-text .clip-text {
  background-image: none;
}

@media (max-width: 575px) {
  .offer-list {
    max-width: 340px;
    margin: 0 auto;
  }
}

.offer-list__item {
  display: flex;
  align-items: center;
  padding: 0.6rem 0;
  padding-right: 1rem;
}

.offer-list__icon {
  width: 30px;
  max-width: 30px;
  flex: 0 0 30px;
}

.offer-list__icon svg {
  fill: #ff5c53;
  font-size: 30px;
  font-size: 1.875rem;
}

.offer-list__text {
  color: #000000;
  font-size: 18px;
  font-size: 1.125rem;
  font-family: "Gilroy-Regular", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.4;
  padding-left: 1.25rem;
  flex: 1;
}

.process {
  position: relative;
}

@media (max-width: 770px) {
  .process:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -70px;
    display: block;
    width: 2px;
    height: 100%;
    border: 1px dashed #eaeaea;
    z-index: -1;
  }
}

.process__item {
  position: relative;
  margin-top: 5rem;
  margin-bottom: 10rem;
}

.process__item:last-child {
  margin-bottom: 0;
}

@media (min-width: 771px) {
  .process__item {
    position: static;
    margin: 0;
  }
}

.process__dot {
  position: absolute;
  top: -90px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

@media (min-width: 771px) {
  .process__dot {
    top: 50%;
  }
}

@media (max-width: 770px) {
  .process__dot {
    left: 50%;
    transform: translateX(-50%);
  }
}

.process__dot:hover:after {
  background-color: #ff5c53;
}

.process__dot:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  transform: translate(-50%, -50%);
  border: 2px solid #ff5c53;
  background-color: #ffffff;
  border-radius: 100%;
}

@media (max-width: 770px) {
  .process__dot:after {
    background-color: #ff5c53;
  }
}

.process__dot.is-active:after {
  background-color: #ff5c53;
}

@media (min-width: 771px) {
  .process__dot.is-active + .process__content {
    opacity: 1;
    visibility: visible;
    padding: 3.125rem 2.25rem;
    filter: drop-shadow(0 5px 30px rgba(175, 175, 175, 0.3));
    background-color: #ffffff;
    transform: translateY(0);
  }
  .process__dot.is-active + .process__content.process__content--center {
    transform: translate(-50%, 0);
  }
}

@media (min-width: 771px) {
  .process__dot--1 {
    left: 0;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 771px) {
  .process__dot--2 {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 771px) {
  .process__dot--3 {
    right: -20px;
    transform: translate(0, -50%);
  }
}

.process__content {
  cursor: default;
}

@media (max-width: 770px) {
  .process__content {
    padding: 3.125rem 2.25rem;
    filter: drop-shadow(0 5px 30px rgba(175, 175, 175, 0.3));
    background-color: #ffffff;
  }
}

@media (min-width: 771px) {
  .process__content {
    position: absolute;
    top: calc(100% - 30px);
    opacity: 0;
    visibility: hidden;
    max-width: 430px;
    transform: translateY(20px);
    transition: transform 250ms ease-out, opacity 250ms ease-out;
    will-change: transform, opacity;
  }
  .process__content.process__content--center {
    transform: translate(-50%, 20px);
  }
}

.process__content:after {
  content: '';
  position: absolute;
  top: -17px;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 17px 18px 17px;
  border-color: transparent transparent #ffffff transparent;
}

@media (max-width: 770px) {
  .process__content:after {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 771px) {
  .process__content--left {
    left: -52px;
  }
  .process__content--left:after {
    left: 35px;
  }
}

@media (min-width: 771px) {
  .process__content--center {
    left: 50%;
    transform: translateX(-50%);
  }
  .process__content--center:after {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 771px) {
  .process__content--right {
    right: -45px;
  }
  .process__content--right:after {
    right: 35px;
  }
}

.process-list {
  position: relative;
  padding-top: 2rem;
  list-style: none;
  padding-left: 0.5rem;
}

.process-list:after {
  content: '';
  position: absolute;
  left: 0.5rem;
  top: 5px;
  display: block;
  width: 2px;
  height: calc(100% - 15px);
  background-color: #f8f8f8;
}

.process-list__item {
  position: relative;
  padding-left: 2.5rem;
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 0.8rem;
  line-height: 1.4;
}

.process-list__item:last-child {
  margin-bottom: 0;
}

.process-list__item:before {
  content: '';
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 6px;
  height: 6px;
  background-color: #ff5c53;
  border-radius: 100%;
}

.process-list__item:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 15px;
  height: 2px;
  background-color: #f8f8f8;
}

.aside-form-content {
  padding: 3.125rem 1.4375rem 2.5rem;
}

@media (min-width: 992px) {
  .aside-form-content {
    padding: 2.6875rem 1.75rem 1.25rem;
  }
}

.category-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -20px;
}

@media (min-width: 576px) {
  .category-list {
    margin: 0;
    justify-content: space-between;
  }
}

.category-list__item {
  display: inline-flex;
  align-items: center;
}

.category-list__item:hover .category-list__link {
  color: #ff5c53;
}

.category-list__item.is-active .category-list__link {
  font-family: "Gilroy-Medium", Arial, sans-serif;
  font-weight: 500;
  color: #ff5c53;
}

@media (min-width: 576px) {
  .category-list__item:first-child .category-list__link {
    padding-left: 0;
  }
  .category-list__item:last-child .category-list__link {
    padding-right: 0;
  }
}

.category-list__link {
  display: inline-block;
  padding: 0.8rem 0.8rem;
  color: #000000;
  font-family: "Gilroy-Light", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.2;
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 576px) {
  .category-list__link {
    padding: 1rem 0.8rem;
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (min-width: 771px) {
  .category-list__link {
    font-size: 18px;
    font-size: 1.125rem;
    padding: 1rem 0.5rem;
  }
}

.simpleParallax {
  height: 100%;
}
