// GENERAL
$project-name: 'project-name';
$project-font-secondary: 'Blacker-Pro-ExBd';
$project-font-primary-300: 'Gilroy-Light';
$project-font-primary-400: 'Gilroy-Regular';
$project-font-primary-500: 'Gilroy-Medium';
$project-font-primary-700: 'Gilroy-Bold';
$debug: false;
$debug-screen: false;
$grid: true;
$dots-size: false;

// BLOCK VARIABLES
$border-radius: 5px;
$transition-duration: 400ms;
$transition-easing: 'ease-out';

// GRID VARIABLES
$grid-columns: 12;
$grid-gutter-width: 30px;

// CONTAINER VARIABLES
$container-tablet: (720px + $grid-gutter-width);
$container-sm: $container-tablet;
$container-desktop: (940px + $grid-gutter-width);
$container-md: $container-desktop;
$container-large-desktop: (1140px + $grid-gutter-width);
$container-lg: $container-large-desktop;

// RESPONSIVE VARIABLES
$screen-xs-phone: 370px;
$screen-phone: 576px;
$screen-tablet: 771px;
$screen-desktop: 992px;
$screen-lg-desktop: 1200px;
$screen-xl-desktop: 1440px;
$screen-xxl-desktop: 1600px;
$screen-xxxl-desktop: 1800px;
$screen-xxxxl-desktop: 2000px;

// FONT SETTINGS
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-primary-light: $project-font-primary-300, Arial, sans-serif;
$font-primary-regular: $project-font-primary-400, Arial, sans-serif;
$font-primary-medium: $project-font-primary-500, Arial, sans-serif;
$font-primary-bold: $project-font-primary-700, Arial, sans-serif;
$font-secondary: $project-font-secondary, Arial, sans-serif;
$font-light: 300;
$font-regular: 400;
$font-bold: 700;
$alfa: 48px;
$beta: 36px;
$gamma: 24px;
$delta: 18px;
$epsilon: 16px;
$zeta: 14px;

// COLORS SETTINGS
$color-black: #000000;
$color-gray: #9b9b9b;
$color-red: #ff5c53;
$color-gray-light: #f8f8f8;
$color-black-light: #030303;
$color-white: #ffffff;

$color-field-error: #ea4335;
$color-field-success: #55a954;

// HEADER VARIABLES
$nav-bg-color: $color-red;
$nav-bg-color-active: $color-white;
