/* HR helper */

.hr-line {
  position: relative;
  margin: 30px 0;
  height: 1px;
  background-color: $color-gray-light;
}

.hr-line-1 {
  margin: 1rem 0;
}

.hr-line-1-3 {
  margin: 1rem 3rem;
}

.hr-line-2 {
  margin: 2rem 0;
}

.hr-line-3 {
  margin: 3rem 0;
}

.hr-line--width-100 {
  width: 100px;
}

.hr-line--width-200 {
  width: 200px;
}

.hr-line--width-300 {
  width: 300px;
}

.hr-line--height-2 {
  height: 2px;
}

.hr-line--center {
  margin: 0 auto;
}

.hr-line--white {
  background-color: $color-white;
}

.hr-line--gray {
  background-color: $color-gray-light;
}
