.category-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -20px;
  @include bp(xsmall) {
    margin: 0;
    justify-content: space-between;
  }
}

.category-list__item {
  display: inline-flex;
  align-items: center;
  &:hover {
    .category-list__link {
      color: $color-red;
    }
  }
  &.is-active {
    .category-list__link {
      font-family: $font-primary-medium;
      font-weight: 500;
      color: $color-red;
    }
  }
  @include bp(xsmall) {
    &:first-child {
      .category-list__link {
        padding-left: 0;
      }
    }
    &:last-child {
      .category-list__link {
        padding-right: 0;
      }
    }
  }
}

.category-list__link {
  display: inline-block;
  padding: 0.8rem 0.8rem;
  color: $color-black;
  font-family: $font-primary-light;
  font-weight: 300;
  line-height: 1.2;
  @include font-size(14px);
  @include bp(xsmall) {
    padding: 1rem 0.8rem;
    @include font-size(16px);
  }
  @include bp(small) {
    @include font-size(18px);
    padding: 1rem 0.5rem;
  }
}
