.hero-section {
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: $color-black;
  padding: 0 0.5rem;
}

.hero-arrow {
  position: absolute;
  bottom: 20px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  transform: translateX(-50%);
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
  cursor: pointer;
  svg {
    fill: $color-red;
    @include font-size(30px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translate(-50%, 0);
  }
  40% {
    transform: translate(-50%, -30px);
  }
  60% {
    transform: translate(-50%, -15px);
  }
}
