/* Form base */

%input {
  width: 100%;
  min-height: 50px;
  padding: 0.2rem 1rem;
  border: 0;
  background-color: $color-white;
  color: $color-black;

  font-family: $font-primary-regular;
  font-weight: 400;
  @include font-size(14px);
  @include border-radius(0);
  outline: none !important;
  -webkit-appearance: none;
  &:placeholder-shown {
    border-color: $color-black;
  }
  &:focus {
    border-color: $color-black;
  }
}

.form__input {
  @extend %input;
}

.form__input--border-bottom {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border-bottom: 1px solid $color-black;
}

.form__textarea {
  height: 165px;
  padding: 1rem 1.5rem;
  line-height: 1.5rem;
  resize: none;

  @extend %input;
  @include border-radius(0);
}

.form__textarea--limit {
  @include bp(xsmall) {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    resize: none;
    min-height: 160px;
    max-height: 250px;
  }
  @include bp(rev-xsmall) {
    height: 200px !important;
  }
}

.form__select {
  width: 100%;
  height: 50px;
  padding: 0.2rem 1rem;
  font-family: $font-primary-regular;
  font-weight: 400;
  line-height: 1.2;
  color: $color-black;
  border: 0;
  outline: none;
  background-color: $color-white;

  @include font-size(16px);
  -webkit-appearance: value;
  -moz-appearance: value;
  -ms-appearance: value;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.form__icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 50px;
  height: 50px;
  @include font-size(22px);
  color: $color-black;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.form__icon-block {
  .form__input {
    padding-right: 50px;
  }
}

.form__label {
  display: block;
  margin: 0 0 10px;
  font-family: $font-primary-regular;
  font-weight: 400;
  color: rgba(25, 25, 25, 0.3);

  @include font-size(16px);
}

.form__field {
  position: relative;
}

.form__field--small {
  margin-bottom: 0.5rem;
}

.form__field--medium {
  margin-bottom: 1rem;
}

.form__field--big {
  margin-bottom: 2rem;
}

.form__placeholder {
  position: relative;
  z-index: 1;
  &.is-active {
    .form__label {
      width: calc(100% - 20px);
      transform: translateX(0) translateY(-1px);
      @include font-size(12px);
      padding: 5px 1rem 5px;
    }
  }
  .form__input,
  .form__textarea {
    padding: 25px 1rem 13px;
  }
  .select2-container .select2-selection--single {
    padding: 7px 40px 0 1rem;
  }
  .form__label {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    margin: 0;
    // padding: 8px 0 2px;
    cursor: text;
    transition: all 0.3s ease;
    transform: translateX(0) translateY(4px);
    text-transform: none;
    pointer-events: none;
    padding: 10px 1rem 10px;
  }
}

.form__placeholder--no-padding {
  &.is-active {
    .form__label {
      transform: translateX(0) translateY(-1px);
      padding: 5px 0 5px;
    }
  }
  .form__input,
  .form__textarea {
    padding: 25px 0 13px;
  }
  .form__label {
    transform: translateX(0) translateY(4px);
    padding: 10px 0 10px;
  }
}

.form__placeholder-textarea {
  .form__label {
    top: 1px;
    left: 2px;
    background: white;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid transparent;
  // -webkit-text-fill-color: transparent;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
