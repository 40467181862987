.team-image-group {
  display: block;
  margin-left: -1rem;
}

.team__item {
  margin-bottom: calculate-rem(80px);
  @include bp(rev-xssmall) {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @include bp(small) {
    margin-bottom: calculate-rem(140px);
  }
}

.team__item--main {
  @include bp(xsmall) {
    margin-bottom: calculate-rem(86px);
  }
  .team__content {
    @include bp(xsmall) {
      display: flex;
      align-items: center;
      .team__image-block {
        width: 250px;
        max-width: 250px;
        flex: 0 0 250px;
        margin-bottom: 0;
      }
      .team__text-block {
        padding-left: calculate-rem(20px);
        width: calc(100% - 250px);
        max-width: calc(100% - 250px);
        flex: 0 0 calc(100% - 250px);
        text-align: left;
      }
      .team__name {
        @include font-size(28px);
      }
      .team__description {
        @include font-size(18px);
      }
      p {
        @include font-size(18px);
      }
    }
    @include bp(small) {
      .team__image-block {
        width: 300px;
        max-width: 300px;
        flex: 0 0 300px;
        margin-bottom: 0;
      }
      .team__text-block {
        padding-left: calculate-rem(42px);
        width: calc(100% - 300px);
        max-width: calc(100% - 300px);
        flex: 0 0 calc(100% - 300px);
      }
    }
  }
}

.team__content {
  &:focus,
  &:hover {
    .team__overlay {
      opacity: 1;
    }
    .team__contact-link {
      transform: scale(1);
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

.team__content--hire {
  &:hover {
    .team__image-block {
      border-color: #ff7d75;
      border-style: solid;
      background-color: #ff7d75;
      svg {
        fill: $color-white;
      }
      .team__hire-text {
        color: $color-white;
      }
    }
  }
}

.team__image-block {
  position: relative;
  background-color: $color-white;
  overflow: hidden;
  border-radius: 100%;
  margin-bottom: calculate-rem(44px);
  background-color: #f9f9f9;
}

.team__image-block--border {
  border: 2px dashed #dedede;
  background-color: transparent;
}

.team__overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(255, 92, 83, 0.8);
  transition: opacity 250ms ease-out;
}

.team__hire {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: opacity 250ms ease-out;
}

.team__hire-text {
  @include font-size(18px);
  color: $color-black;
}

.team__contact-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 10px;
  transform: scale(0);
  transition: transform 250ms ease-out;
  svg {
    @include font-size(28px);
    fill: $color-white;
  }
}

.team__text-block {
  p {
    @include bp(rev-xsmall) {
      @include font-size(14px);
    }
  }
}

.team__name {
}

.team__position {
  display: block;
  margin-bottom: 0.8rem;
  color: $color-gray;
  line-height: 1.2;
  @include font-size(14px);
}
