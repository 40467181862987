.swiper-arrow {
  position: absolute;
  top: 90px;
  outline: none;
  z-index: 6;
  &.swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
}

.swiper-arrow-next {
  right: 0;
  @include bp(small) {
    right: -15px;
  }
}

.swiper-arrow-prev {
  left: 0;
  @include bp(small) {
    left: -15px;
  }
}

.swiper-arrow-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
  background-color: $color-white;
  border-radius: 100%;
  transition: background-color 250ms ease-out;
  @include bp(small) {
    height: 65px;
    width: 65px;
  }
  &:focus,
  &:hover {
    background-color: $color-red;
    svg {
      fill: $color-white;
    }
  }
  svg {
    fill: $color-black;
    @include font-size(24px);
  }
}
