/* Size helpers */

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.h-vh-100 {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50%;
}

.h-25 {
  height: 25%;
}
