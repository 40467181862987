.clip-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.clip-text-gray {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#32373a),
    color-stop(85%, rgba(30, 36, 29, 0))
  );
  background-image: linear-gradient(180deg, #f4f4f4 0%, $color-white 100%);
  color: #f4f4f4 !important;
}

.no-background-clip-text .clip-text {
  background-image: none;
}
