.file-upload__input {
  position: absolute;
  z-index: -1;
  overflow: hidden;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  & + .file-upload__label {
    display: inline-block;
    overflow: hidden;
    margin-bottom: 0;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.file-upload__label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    .file-upload__text {
      color: darken($color-red, 20%);
    }
  }
  svg {
    position: relative;
    top: 3px;
  }
}

.file-upload__text {
  text-decoration: underline;
  color: $color-red;
  font-family: $font-primary-regular;
  font-weight: 400;
  @include font-size(14px);
}

.file-upload__output {
  display: block;
  margin-top: 10px;
  line-height: 1;
  color: $color-black;

  @include font-size(14px);
}

.file-upload__icon {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
  vertical-align: middle;
  top: -2px;
  &:before,
  &:after {
    position: absolute;
    display: block;
    width: 20px;
    height: 2px;
    content: '';
    background: #000;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -1px;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
