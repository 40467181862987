/* Typography */

.typography {
  p,
  li,
  td,
  th {
    line-height: 1.5em;
  }

  p,
  ul,
  ol,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  table,
  fieldset,
  blockquote,
  iframe {
    margin-bottom: 1rem;
  }

  h1 {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: $font-secondary;
    font-weight: 700;
    color: $color-black;
    @include font-size(26px);
    @include bp(small) {
      @include font-size(30px);
    }
  }

  h2 {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: $font-secondary;
    font-weight: 700;
    color: $color-black;

    @include font-size(24px);
    @include bp(small) {
      @include font-size(26px);
    }
  }

  h3 {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: $font-secondary;
    font-weight: 700;
    color: $color-black;

    @include font-size(22px);
    @include bp(small) {
      @include font-size(24px);
    }
  }

  h4 {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: $font-secondary;
    font-weight: 700;
    color: $color-black;

    @include font-size(20px);
    @include bp(small) {
      @include font-size(22px);
    }
  }

  h5 {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: $font-secondary;
    font-weight: 700;
    color: $color-black;

    @include font-size(18px);
    @include bp(small) {
      @include font-size(20px);
    }
  }

  h6 {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: $font-secondary;
    font-weight: 700;
    color: $color-black;

    @include font-size(18px);
    @include bp(small) {
      @include font-size(18px);
    }
  }

  a {
    text-decoration: underline;
    color: $color-red;
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    display: block;
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-family: $font-primary-regular;
    font-weight: 400;
    line-height: 1.6;
    color: $color-black-light;
    @include font-size(16px);
  }

  strong {
    font-family: $font-primary-bold;
    font-weight: 700;
    color: $color-black-light;
  }

  img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
  }

  ul,
  ol {
    margin-bottom: 2.5rem;
    padding-left: 0;
    list-style: none;
    li {
      position: relative;
      margin-bottom: 0.3rem;
      padding-left: 1.5rem;
      font-family: $font-primary-regular;
      font-weight: 400;
      line-height: 1.6;
      color: $color-black;

      @include font-size(16px);
    }
  }

  ul {
    li {
      &:before {
        position: absolute;
        top: 13px;
        left: 3px;
        display: block;
        width: 6px;
        height: 1px;
        content: '';
        color: $color-black;
        border-radius: 100%;
        background-color: $color-black;
      }
    }
  }

  ol {
    list-style-position: inside;
    counter-reset: item;
    li {
      &:before {
        position: absolute;
        top: 1px;
        left: 0;
        display: inline-block;
        line-height: 1.4;
        content: counter(item) '.';
        counter-increment: item;
        color: $color-black;

        @include font-size(16px);
      }
    }
  }

  figure {
    margin: 0;
    margin-bottom: 2rem;
    img {
      margin: 0;
    }
  }

  figcaption {
    margin-top: 0.6rem;
    font-family: $font-primary-regular;
    font-weight: 400;
    color: $color-black;
    @include font-size(12px);
  }
}
